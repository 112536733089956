<template>
    <div class="flex-1 min-w-0">
        <div>
            <h2 class="mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight">
                {{ __("Crea Email") }}
            </h2>
            <div class="mx-auto sm:px-6">
                <form class="p-6 m-6 border border-black rounded" @submit.prevent="createMailNotification()">
                    <div class="flex flex-wrap py-2 justify-around">
                        <div class="grid py-2">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                {{ __("Ruolo") }}
                            </label>
                            <div>
                                <select class="w-full" v-model="newMailNotification.role">
                                    <option v-for="(option, indexO) in options" :key="indexO" type="radio"
                                        :value="option">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="grid py-2">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                {{ __("Email") }}
                            </label>
                            <input required type="email"
                                class="md:w-80 appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                v-model="newMailNotification.email" />
                        </div>
                        <div class="grid py-2">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                {{ __("") }}
                            </label>
                            <button type="submit"
                                class="rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                                {{ __("Crea") }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="mailNotifications && mailNotifications.length > 0">
            <h2 class="mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight">
                {{ __("Lista Email") }}
            </h2>
            <div class="mx-auto sm:px-6">
                <div class="overflow-scroll sm:rounded-lg">
                    <table class="p-6 m-6 bg-white shadow-xl whitespace-no-wrapw-full whitespace-no-wrap">
                        <tr v-for="(mailNotification, i) in mailNotifications" :key="i">
                            <td class="border px-4">
                                {{ mailNotification.role }}
                            </td>
                            <td class="border px-4">
                                {{ mailNotification.email }}
                            </td>
                            <td class="border px-4">
                                <button @click="deleteMailNotification(i)"
                                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                                    {{ __("Elimina") }}
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div>
            <h2 class="mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight">
                {{ __("Messaggi Email") }}
            </h2>

            <div class="mx-auto sm:px-6">
                <div v-for="(mailBody, i) in mailBodies" :key="i">
                    <form class="p-6 m-6 border border-black rounded" @submit.prevent="updateMailBody(i)">
                        <label class="block uppercase tracking-wide text-gray-700 font-bold mb-2">
                            {{ mailBody.name }}
                        </label>
                        <div class="border border-black rounded p-2">
                            <label class="block tracking-wide text-gray-700 font-bold mb-2 text-xs">
                                {{__('Legenda')}}
                            </label>
                            <label v-for="(phrase, index) in mailBody.description.split('\\n')" :key="index"
                                class="block tracking-wide text-gray-700 mb-2 text-s">
                                {{ phrase }}
                            </label>
                        </div>
                        <div class="flex flex-wrap py-2 justify-around">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                {{ __("Intestazione Email") }}
                            </label>
                            <textarea
                                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                v-model="mailBody.subject">
                            </textarea>
                        </div>
                        <div class="flex flex-wrap py-2 justify-around">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                {{ __("Corpo Email") }}
                            </label>
                            <textarea
                                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                v-model="mailBody.body">
                            </textarea>
                        </div>
                        <div class="flex flex-wrap pt-2 justify-between">
                            <div v-if="mailBody.rangeMinutes != null" class="flex grid pt-2">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    {{ __("Range Minuti Attesa") }}
                                </label>
                                <input type="number" min="1"
                                    class="appearance-none block w-20 bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    v-model="mailBody.rangeMinutes" />
                            </div>
                            <div class="flex flex-wrap pt-2">
                                <button type="submit"
                                    class="w-full m-auto inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                                    {{ __("Aggiorna") }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            newMailNotification: {},
            options: [
                "admin",
                "filiale_01",
                "filiale_02",
                "filiale_03",
                "filiale_04",
                "filiale_06",
                "filiale_07",
                "filiale_08"
            ],
            mailNotifications: [],
            mailBodies: []
        };
    },
    async created() {
        await this.initMailNotifications();
        await this.initMailBodies();
    },
    methods: {
        async createMailNotification() {
            await this.storeLogTime(async () => {
                const res = await this.handleCallback(
                    () =>
                        this.axios.post(
                            "/admin/create-mail-notification",
                            this.newMailNotification
                        ),
                    true
                );
                await this.initMailNotifications();
                return res;
            });
        },
        async deleteMailNotification(i) {
            await this.storeLogTime(async () => {
                const res = await this.handleCallback(
                    () =>
                        this.axios.delete(
                            "/admin/delete-mail-notification?id=" +
                            this.mailNotifications[i].id
                        ),
                    true
                );
                await this.initMailNotifications();
                return res;
            });
        },
        async initMailNotifications() {
            await this.storeLogTime(async () => {
                this.newMailNotification = { role: "admin", email: null };
                const res = await this.handleCallback(() =>
                    this.axios.get("/admin/mail-notifications")
                );
                this.mailNotifications = res.value || res;;
                return res;
            });
        },

        async updateMailBody(i) {
            await this.storeLogTime(async () => {
                const res = await this.handleCallback(
                    () =>
                        this.axios.post(
                            "/admin/update-mail-body",
                            this.mailBodies[i]
                        ),
                    true
                );
                await this.initMailBodies();
                return res;
            });
        },
        async initMailBodies() {
            await this.storeLogTime(async () => {
                const res = await this.handleCallback(() =>
                    this.axios.get("/admin/mail-bodies")
                );
                this.mailBodies = res.value || res;
                return res;
            });
        }
    }
};
</script>
