<template>
<div v-if="modal.show" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="os-min-hscreen min-w-screen h-full block p-5" :class="isMobile() ? 'text-center' : 'flex flex-col justify-center items-center'">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span class="hidden inline-block align-middle" aria-hidden="true">&#8203;</span>
                <div :class="`flex flex-col justify-center items-center bg-white rounded-lg overflow-hidden shadow-xl transform transition-all ${modal.color ? modal.color : ''} ${isMobile() ? 'h-full w-full' : ''}`">
                    <div class="px-4 pt-5 pb-4 p-6 pb-4">
                        <div class="flex items-start">
                            <div class="mt-3mt-0 ml-4">
                                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                {{modal.title}}
                                </h3>
                                <div class="mt-2" v-for="(b, index) in modal.body" :key="index" v-html="b.value" @click="() => b.action()"
                                :class="{'mt-4 cursor-pointer bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded': b.action}">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="modal.buttons != undefined" class="px-4 px-6 flex flex-row-reverse">
                        <button v-for="(button, index) in modal.buttons" :key="index" type="button" @click="button.click()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 my-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 ml-3 w-auto text-sm">
                            {{button.title}}
                        </button>
                    </div>
                    <div v-else class="px-4 px-6 flex flex-row-reverse">
                        <button type="button" @click="modal.show = false" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 ml-3 w-auto text-sm">
                        {{__('CHIUDI')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
export default {
    props: {
        modal: Object
    }
}
</script>
