<template>
    <div>
        <div class="flex flex-wrap">
            <div class="md:w-1/2 px-3 w-full my-2">
                <AutoCompleteLocal :title="__('Ragione Sociale')" :options="rubricaRitiri" ref="DESCRIZIONE"
                    :reff="refs.DESCRIZIONE" :resend="resend" :text="data.DESCRIZIONE"
                    :mapOptionTitle="(option) => formatRitiroRubrica(option)" @chosen="(c) => {
                        data.DESCRIZIONE = c.text;
                        if (c.selectedOption) loadRitiroRubrica(c.selectedOption);
                    }"></AutoCompleteLocal>
            </div>
            <div class="md:w-1/2 px-3 w-full my-2">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" :for="`lr_indirizzo_${indexel}`">
                    {{ __('Indirizzo') }}
                </label>
                <input maxlength="30" :ref="refs.IN"
                    :class="{ 'bg-red-400 focus:bg-red-400': !data.INDIRIZZO && resend }"
                    class="text-xs appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                       :id="`lr_indirizzo_${indexel}`" @change="emit()" v-model="data.INDIRIZZO" type="text" placeholder="...">
            </div>
        </div>

        <div class="flex flex-wrap">
            <div class="md:w-1/3 px-3 w-full my-2">
                <AutoComplete :title="__('Città')" :url="url" :text="auto_complete.OFLOC.text" ref="OFLOC"
                    :reff="refs.OFLOC" :resend="resend" :selectedOption="auto_complete.OFLOC.selectedOption"
                    :mapOptionTitle="(option) => option.length > 0 ? option[0].value : ''" @cancelCallback="cancelAll()"
                    @chosen="(c) => chosenAutoComplete(c, 'OFLOC')"
                    :searchOptions="(url, word) => searchOptions(url, word, ['OFLOC'])"
                    :showOptionsOut="showOptions.OFLOC" :autoSelectOneOption="true" :autocomplete="true">
                </AutoComplete>
            </div>
            <div class="md:w-1/3 px-3 w-full my-2">
                <AutoComplete :title="__('CAP')" :url="url" :text="auto_complete.OFCAP.text" ref="OFCAP"
                    :reff="refs.OFCAP" :resend="resend" :selectedOption="auto_complete.OFCAP.selectedOption"
                    :mapOptionTitle="(option) => option.length > 0 ? option[0].value : ''"
                    @chosen="(c) => chosenAutoComplete(c, 'OFCAP')"
                    :searchOptions="(url, word) => searchOptions(url, word, ['OFCAP'])"
                    :showOptionsOut="showOptions.OFCAP" :autoSelectOneOption="true" :autocomplete="true">
                </AutoComplete>
            </div>
            <div class="md:w-1/3 px-3 w-full my-2">
                <AutoComplete :title="__('PROVINCIA')" :url="url" :text="auto_complete.OFCPD.text" ref="OFCPD"
                    :reff="refs.OFCPD" :resend="resend" :selectedOption="auto_complete.OFCPD.selectedOption"
                    :mapOptionTitle="(option) => option.length > 0 ? option[0].value : ''"
                    @chosen="(c) => chosenAutoComplete(c, 'OFCPD')"
                    :searchOptions="(url, word) => searchOptions(url, word, ['OFCPD'])"
                    :showOptionsOut="showOptions.OFCPD" :autoSelectOneOption="true" :readonly="true"></AutoComplete>
            </div>
        </div>
        <div class="flex flex-wrap justify-center">
            <div class="cursor-pointer px-3 my-2 flex inline" @click="createRitiroRubrica()">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="dodgerblue">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                </svg>
                <div class="px-2">{{ __('salva in rubrica') }}</div>
            </div>
            <div class="cursor-pointer px-3 my-2 flex inline" @click="showModal()">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="dodgerblue">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                </svg>
                <div class="px-2">{{ __('apri rubrica') }}</div>
            </div>
        </div>

        <!-- modal -->
        <div v-if="openModal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
            aria-modal="true">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div
                    class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div class="sm:flex sm:items-start">
                            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                    {{ __('Carica dalla Rubrica') }}
                                </h3>
                                <div v-for="(ritiro, index) in rubricaRitiri" :key="index"
                                    class="flex inline justify-between">
                                    <div class="mt-2 m-4 text-center cursor-pointer bg-transparent hover:bg-red-700 text-red-700 font-semibold hover:text-white p-2 border border-red-700 hover:border-transparent rounded"
                                        @click="loadRitiroRubrica(ritiro)">
                                        {{ formatRitiroRubrica(ritiro) }}
                                    </div>
                                    <div v-if="ritiro.isDefault != 1 || (tipor && tipor != 'a')">
                                        <div v-if="tipor && tipor == 'a'" @click="setDefaultRitiroRubrica(ritiro)"
                                            class="flex flex-wrap justify-center m-4 text-center cursor-pointer bg-transparent text-red-700 font-semibold hover:text-white p-2 border border-red-700 rounded hover:bg-red-700">
                                            &#9734;
                                        </div>
                                        <div @click="deleteRitiroRubrica(ritiro)"
                                            class="flex flex-wrap justify-center m-4 text-center cursor-pointer bg-transparent text-red-700 font-semibold hover:text-white p-2 border border-red-700 rounded hover:bg-red-700">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div v-else class="m-4 text-center bg-transparent text-red-700 font-semibold p-2">
                                        &#x2605;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-gray-50 px-4 sm:px-6 sm:flex sm:flex-row-reverse m-2">
                        <button type="button" @click="openModal = false"
                            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700 sm:ml-3 sm:w-auto sm:text-sm">
                            {{ __('Esci') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AutoComplete from '../filters/AutoComplete.vue';
import AutoCompleteLocal from '../filters/AutoCompleteLocal.vue';
import SelectOption from '../filters/SelectOption.vue';

export default {
    components: {
        AutoComplete,
        SelectOption,
        AutoCompleteLocal
    },
    props: {
        tipor: String,
        data: Object,
        auto_complete: Object,
        indexel: {
            type: Number,
            default: 0
        },
        resend: {
            type: Boolean,
            default: false
        },
        refs: {
            type: Object,
            default: function () {
                return {
                    DESCRIZIONE: '',
                    INDIRIZZO: '',
                    OFLOC: '',
                    OFCPD: '',
                    OFCAP: ''
                }
            }
        }
    },
    data() {
        return {
            openModal: false,
            types: ['OFCAP', 'OFLOC', 'OFCPD'],
            rubricaRitiri: [],
            showOptions: {
                OFLOC: false,
                OFCAP: false,
                OFCPD: false
            },
            deleteFlag: true,
        }
    },
    computed: {
        url() {
            return this.$store.getters.uri + '/params-localita';
        }
    },
    async created() {
        this.data.DESCRIZIONE = '';
        await this.initModal();
        if (this.tipor == "a")
            this.loadRitiroRubrica(this.rubricaRitiri.find(v => v.isDefault == "1"));
    },
    beforeUpdate() {
        this.emit();
    },
    methods: {
        formatRitiroRubrica(ritiro) {
            return (ritiro.ragionesociale ? ritiro.ragionesociale + ' - ' : '') + (ritiro.cap ? ritiro.cap + ' - ' : '') + (ritiro.indirizzo ? ritiro.indirizzo + ' - ' : '') + (ritiro.localita ? ritiro.localita + ' - ' : '') + (ritiro.prov ? ritiro.prov : '');
        },
        async showModal() {
            await this.initModal();
            this.openModal = true;
        },
        async loadRitiroRubrica(ritiro) {
            this.openModal = false;
            this.cancelAll();
            if (!ritiro || ritiro === undefined) {
                return;
            }

            this.data.DESCRIZIONE = ritiro.ragionesociale;
            this.data.INDIRIZZO = ritiro.indirizzo;
            for (const {type, text} of [
                {type: "OFLOC", text: ritiro.localita},
                {type: "OFCAP", text: ritiro.cap},
                {type: "OFCPD", text: ritiro.prov},
            ]) {
                if (text.length > 0) {
                    const res = await this.searchOptions(this.url, text, [type]);
                    const data = res.data;
                    if (data.length > 0) {
                        const val = data.find(r => r[0].value.trim() == text.trim());
                        if (val) {
                            this.assignSingleFilter(text, type);
                        }
                    }
                }
            }

        },
        deleteRitiroRubrica(ritiro) {
            this.searchBase(this.$store.getters.uri + '/remove-rubrica-ritiri', { id: ritiro.id });
            this.initModal();
        },
        setDefaultRitiroRubrica(ritiro) {
            this.searchBase(this.$store.getters.uri + '/set-default-rubrica-ritiri', { id: ritiro.id });
            this.initModal();
        },
        emit() {
            this.$emit('chosen', this.data);
        },
        async initModal() {
            this.rubricaRitiri = await this.searchBase(this.$store.getters.uri + '/rubrica-ritiri', { tipor: this.tipor });
        },
        async createRitiroRubrica() {
            await this.storeLogTime(async () => {
                let res = null;
                if (this.data.DESCRIZIONE && this.data.INDIRIZZO && this.data.OFCAP && this.data.OFLOC && this.data.OFCPD) {
                    this.data.tipor = this.tipor;
                    res = await this.handleCallback(() => this.axios.post(this.$store.getters.uri + '/insert-rubrica-ritiri', { fields: this.data }), true);
                    this.initModal();
                } else
                    this.$emit('notify', false, this.__('Campi mancanti'));
                return res;
            });
        },
        assignFilterValues(chosen, type) {
            this.auto_complete[type].text = chosen.text;
            this.auto_complete[type].selectedOption = chosen.selectedOption ? chosen.selectedOption : [];
            this.data[type] = chosen.selectedOption ? chosen.selectedOption[0].value : null;
        },
        async chosenAutoComplete(chosen, type) {
            this.assignFilterValues(chosen, type);
            const filters = [this.types.filter(t => this.data[t]).map(t => this.createFilter(t))];
            const searched = await this.searchBase(
                this.$store.getters.uri + '/localita',
                {
                    filters,
                    risultati_pagina: 150
                }
            ).then(r => r.data);

            if (searched.length == 1) {
                this.types.forEach(type => {
                    this.assignSingleFilter(searched[0][type], type);
                });
            } else if (chosen.selectedOption) {
                this.focusOther(this.types.filter(f => f != type));
            }

            // TODO: soluzione ottimale se campo provincia pulito (ora il campo provincia contiene valori con e senza caratteri speciali (es. ['MI', 'MI\r']))
            // const types_not_single = [];
            // for(const type of this.types) {
            //     const value_counts = [...new Set(searched.map(v => v[type]))];
            //     console.log(type, value_counts)
            //     if (value_counts.length == 1) {
            //         this.assignSingleFilter(value_counts[0], type);
            //     } else {
            //         types_not_single.push(type);
            //     }
            // }
            // this.focusOther(types_not_single);
        },
        cancelAll(types = this.types) {
            for (const type of types) {
                this.assignFilterValues({ text: '' }, type);
            }
        },
        focusOther(types = this.types) {
            for (const type of types) {
                if (!this.data[type] || this.data[type].length == 0) {
                    const ref = this.$refs[type].$refs[this.refs[type]];
                    ref.click();
                }
            }
        },
        assignSingleFilter(value, type) {
            this.data[type] = value;
            this.assignFilterValues({ text: this.data[type], selectedOption: [this.createFilter(type)] }, type);
        },
        createFilter(type) {
            return {
                field: type,
                value: this.data[type],
                type: "text",
                operator: "="
            };
        },
        searchOptions(url, word, params) {
            let filters = [];
            this.types.forEach(t => {
                if (this.data[t])
                    filters.push([this.createFilter(t)]);
            });
            return this.searchBase(url, { word: word, params: params, filters: filters });
        },
        async searchBase(url, data = null) {
            let res = null;
            await this.storeLogTime(async () => {
                res = await this.handleCallback(() => this.axios.post(url, data), false, true, false);
                return res;
            });
            return res.value ? res.value : res;
        },
    }
}
</script>
