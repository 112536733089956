<template>
    <div id="main height-100">
      <Loading v-if="loading"></Loading>
      <Notification :notifications="notifications"></Notification>
      <div>
        <header id="header" v-if="$store.state.user && !ifCourierAndMobile()">
            <Menu @setLoading="setLoading" @notify="notify"></Menu>
        </header>
        <div id="content">
            <router-view @setLoading="setLoading" @notify="notify" @openModal="openModal" @updateTerms="updateTerms"></router-view>
        </div>

        <Modal v-if="genericModal" :modal="genericModal"></Modal>
        <Modal v-if="$store.state.user && $store.state.show_consenso_webtracking" :modal="terms"></Modal>
        <Modal v-if="$store.state.user && $store.state.show_consenso_pod" :modal="termsPod"></Modal>

      </div>
    </div>
</template>
<script>
  import Menu from './menu/Menu.vue';
  import Notification from './utility/Notification.vue';
  import Loading from './utility/Loading.vue';
  import Modal from './utility/Modal.vue';

  export default {
    components: {
      Menu,
      Notification,
      Loading,
      Modal
    },
    data() {
      return {
        loading: false,
        notifications: [],
        terms: {},
        termsPod: {},
        genericModal: undefined,
      }
    },
    async created() {
      this.updateTerms();
    },
    methods: {
      setLoading(flag) {
        this.loading = flag;
      },
      notify(status, title, message = undefined) {
        let notification = {
          status: status,
          title: title,
          message: message
        };
        this.notifications.push(notification);
        setTimeout(() => this.$delete(this.notifications, 0), 3000);
      },
      openModal(modal) {
        modal.show = true;
        this.genericModal = modal;
      },
      async updateTerms() {
        const user = (await this.axios.get('/auth/user')).data;
        this.$store.commit('show_consenso_webtracking', !user.consenso_webtracking);
        await this.initTerms();
        await this.initTermsPod();
      },
      async initTerms() {
        if(this.$store.state.user) {
          this.terms = (await this.axios.get('tracking-event?id=CONSENSO_WEBTRACKING')).data;
          this.terms.body = [{value: this.terms.description}];
          this.terms.show = true;
          this.terms.buttons = [
            {
              title: 'Acconsento',
              click: async () => {
                await this.axios.post('/terms', {terms: true});
                this.$store.commit('show_consenso_webtracking', false);
              }
            },
            {
              title: 'Logout',
              click: async () => {
                let res = (await this.axios.post("/auth/logout")).data;
                if(res) {
                  this.$store.commit('logout');
                  this.$router.push({ name: 'login' });
                }
              }
            }
          ];
        }
      },
      async initTermsPod() {
        if(this.$store.state.user) {
          this.termsPod = (await this.axios.get('tracking-event?id=CONSENSO_POD')).data;
          this.termsPod.body = [{value: this.termsPod.description}];
          this.termsPod.show = true;
          this.termsPod.buttons = [
            {
              title: 'Acconsento',
              click: async () => {
                await this.axios.post('/terms-pod', {terms: true});
                this.$store.commit('show_consenso_pod', false);
              }
            },
            {
              title: 'Esci',
              click: () => {
                this.$store.commit('show_consenso_pod', false);
              }
            }
          ];
        }
      },
     ifCourierAndMobile() {
        return  this.$store.state.user.role == 'courier' && ((window.innerWidth <= 768) || (window.innerHeight <= 768));
      }
    }
  }
</script>
