<template>
    <div class="-mr-2 flex items-center sm:hidden">
        <button @click="menuAction"
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition">
            <span aria-hidden="true" class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out" :class="{'rotate-45': openMenu,' -translate-y-1.5': !openMenu }"></span>
            <span aria-hidden="true" class="block absolute  h-0.5 w-5 bg-current   transform transition duration-500 ease-in-out" :class="{'opacity-0': openMenu } "></span>
            <span aria-hidden="true" class="block absolute  h-0.5 w-5 bg-current transform  transition duration-500 ease-in-out" :class="{'-rotate-45': openMenu, ' translate-y-1.5': !openMenu}"></span>
        </button>
    </div>
</template>
<script>
    export default {
        props: {
            openMenu: Boolean
        },
        methods: {
            menuAction() {
                this.openMenu = !this.openMenu;
                this.$emit('menuAction', this.openMenu);
            }
        }
    }
</script>