<template>
    <div>
        <label v-if="title" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            {{title}}
        </label>
        <div>
            <select class="text-xs w-full"
                v-model="selectedOption"
                @change="$emit('chosen', selectedOption)">
                <option type="radio" :value="[]">
                    {{header}}
                </option>
                <option 
                    v-for="(option, indexO) in options" 
                    :key="indexO" type="radio" 
                    :value="option" 
                >
                    {{mapOptionTitle(option)}}
                </option>
            </select>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        mapOptionTitle: Function,
        searchOptions: Function,
        title: String,
        url: String,
        src: String,
        header: {
            typr: String,
            default: '---'
        },
        selectedOption: {
            type: Array,
            default() {
                return []
            }
        },
        params: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            options: []
        }
    },
    async created() {
        if(this.src === "remote") {
            let page = await this.searchOptions(this.url);
            page.data.forEach(d => this.options.push(d));
            while(page && page.to > 0 && page.to % page.per_page === 0) {
                page = await this.searchOptions(page.next_page_url);
                page.data.forEach(d => this.options.push(d));
            }
        } else if(this.src === "local") {
            this.params.forEach(d => this.options.push(d));
        }
    }
}
</script>