<template>
  <div class="flex justify-center mt-20">
    <div class="w-full sm:w-7/12">
      <span class="flex flex-wrap justify-around">
        <div name="logo" class="w-5/12 flex">
          <img src="/img/TardiniLogo.svg" />
        </div>
        <div
          class="w-5/12 flex uppercase tracking-wide text-gray-700 font-bold flex-wrap justify-center content-end text-center">
          <h2>WEB TRACKING</h2>
        </div>
      </span>
      <div class="p-8 bg-white rounded shadow-xl">
        <form @submit.prevent="login">
          <div class="mt-4">
            <div class="block text-sm text-gray-00" for="Username" :value="__('Username')"></div>
            <input class="w-full px-2 py-2 text-gray-700 bg-gray-100 rounded" id="email" type="text" name="username"
              placeholder="username" v-model="data.username" required autofocus />
          </div>
          <div class="mt-4">
            <div class="block text-sm text-gray-00" for="password" :value="__('Password')"></div>
            <input class="w-full px-2 py-2 text-gray-700 bg-gray-100 rounded" id="password" type="password"
              placeholder="password" name="password" v-model="data.password" required autocomplete="current-password" />
          </div>
          <!--div class="block mt-4">
            <label for="remember" class="flex items-center">
              <input type="checkbox" id="remember" name="remember" v-model="data.remember"/>
              <span class="ml-2 text-sm text-gray-600">{{ __('Ricordami') }}</span>
            </label>
          </div-->
          <div class="flex items-center justify-end mt-4">
            <!--router-link class="m-4 underline text-sm text-gray-600 hover:text-gray-900" to="/forgotpassword">
                {{ __('Password dimenticata?') }}
                </router-link-->
            <button class="px-4 py-1 focus:outline-none text-white font-light tracking-wider bg-button-red rounded">
              {{ __('Log in') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {
        username: undefined,
        password: undefined,
        remember: false
      },
    }
  },
  methods: {
    async login() {
      await this.storeLogTime(async () => {
        let res = await this.handleCallback(() => this.axios.post("/auth/login", this.data));
        if (res) {
          this.$store.commit('login', res.jwt);
          this.axios.defaults.headers['Authorization'] = 'Bearer ' + this.$store.state.jwt;
          this.$emit('updateTerms');

          //Set Show DHL Flag
          this.$store.commit('show_dhl_tracking_link', res.sdtl);


          // chiamata per prendere rotte appartenenti all'utente dal backend e selezione del primo elemento che ha l'attributo "first_page" settato a true
          const first_page = (await this.axios.get('/menuitems')).data.find(i => i.first_page);
          if (first_page) {
            this.$router.push({ path: first_page.link });
          } else {
            this.$router.push({ name: 'dashboard' });
          }
        }
        return res;
      });
    }
  }
}
</script>
