<template>
    <div class="flex-1 min-w-0">
        <h2 class="mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight">
            {{__("Notifiche")}}
        </h2>
        <div class="p-4">
            <button @click="deleteAllNotification()"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">{{
                __("Elimina Tutte") }}</button>
        </div>
        <div v-if="notifications.length>0">
            <div class="overflow-scroll sm:rounded-lg">
                <table class="p-6 m-6 bg-white shadow-xl whitespace-no-wrapw-full whitespace-no-wrap">
                    <tr>
                        <th class="border bg-red-500 text-white px-6 py-4">{{__('Id')}}</th>
                        <th class="border bg-red-500 text-white px-6 py-4">{{__('Dato')}}</th>
                        <th class="border bg-red-500 text-white px-6 py-4">{{__('Tipo')}}</th>
                        <th class="border bg-red-500 text-white px-6 py-4">{{__('Ritrasmissioni')}}</th>
                        <th class="border bg-red-500 text-white px-6 py-4">{{__('Ultimo Aggiornamento')}}</th>
                    </tr>
                    <tr v-for="(notification, i) in notifications" :key="i">
                        <td class="border px-4">
                            {{ notification.id }}
                        </td>
                        <td class="border px-4">
                            {{ notification.field }}
                        </td>
                        <td class="border px-4">
                            {{ notification.type }}
                        </td>
                        <td class="border px-4">
                            {{ notification.times }}
                        </td>
                        <td class="border px-4">
                            {{ notification.lastUpdate }}
                        </td>
                        <td class="border px-4">
                            <button @click="deleteNotification(i)"
                                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                                {{ __("Elimina") }}
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div v-else class="text-center">
            {{__('Non sono presenti notifiche per il momento')}}
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            notifications: []
        }
    },
    async created() {
        await this.initNotifications();
    },
    methods: {
        async deleteNotification(i) {
            await this.storeLogTime(async () => {
                const res = await this.handleCallback(() => this.axios.delete("/admin/delete-mail-sended-element?id=" + this.notifications[i].id), true);
                await this.initNotifications();
                return res;
            });
        },
        async deleteAllNotification() {
            await this.storeLogTime(async () => {
                const res = await this.handleCallback(() => this.axios.delete("/admin/delete-all-mail-sended-element"), true);
                await this.initNotifications();
                return res;
            });
        },
        async initNotifications() {
            await this.storeLogTime(async () => {
                const res = await this.handleCallback(() => this.axios.get("/admin/mail-sended-elements"));
                this.notifications = res.value || res;
                return res;
            });
        }
    }
}
</script>