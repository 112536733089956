<template>
    <div class="flex-1 min-w-0">
        <h2 class="mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight">
            {{__("Anagrafiche Corrieri")}}
        </h2>
        <div class="mx-auto sm:px-6 py-6">
            <div v-if="corriere">
                <SetUser :user="corriere" role="courier" @clear="clear" @createUser="createUser" @annulla="clear">
                </SetUser>
            </div>
            <div v-else>
                <GeneralFilter :in_rows="
                [
                    [
                        {
                            type: 'auto_complete',
                            title: __('Codice corriere'), 
                            params: ['CORRIERE']
                        },
                        {
                            type: 'auto_complete',
                            title: __('Nome'), 
                            params: ['NOMECORR']
                        }
                    ],
                    [
                        {
                            type: 'auto_complete',
                            title: __('Filiale'), 
                            params: ['FOFIL']
                        },
                        {
                            type: 'auto_complete',
                            title: __('Codice Fornitore'), 
                            params: ['FOCOD']
                        }
                    ],
                    [
                        {
                            type: 'auto_complete',
                            title: __('Ragione Sociale'), 
                            params: ['FOBRE']
                        },
                        {
                            type: 'auto_complete',
                            title: __('P. IVA'), 
                            params: ['FOPIV']
                        }
                    ],
                    [
                        {
                            type: 'auto_complete',
                            title: __('Username'), 
                            params: ['username']
                        },
                        {
                            title: __('Stato Attivazione'),
                            type: 'select_option',
                            src: 'local',
                            mapOptionTitle: (option) => option[0].label,
                            header: __('Tutti'),
                            params: [
                            [{
                                operator: 'IS NOT',
                                field: 'username',
                                type: 'text',
                                value: 'NULL',
                                label: __('Attivi')
                            }],
                            [{
                                operator: 'IS',
                                field: 'username',
                                type: 'text',
                                value: 'NULL',
                                label: __('Non Attivi')
                            }]
                            ]
                        }
                    ]
                ]" :url="$store.getters.uri+'/params-corrieri'" @search="search"></GeneralFilter>
                <Table :items="[
                    {label: __('Codice'), value: 'codice', type: 'combination',
                        map: (obj) => obj['FOFIL']+obj['FOCOD'],
                        subItems: ['FOFIL', 'FOCOD'],
                    },
                    {label: __('Codice Corriere'), value: 'CORRIERE', type: 'text'},
                    {label: __('Nome'), value: 'NOMECORR', type: 'text'},
                    {label: __('Ragione Sociale'), value: 'FOBRE', type: 'text'},
                    {label: __('P. IVA'), value: 'FOPIV', type: 'text'},
                    {label: __('Username'), value: 'username', type: 'text', denyOrd: true},
                    {label: __('Scegli'), values: (obj) => obj.new ? ['Crea'] : ['Modifica', 'Elimina'], type: 'buttons', onclick: [scegliCorriere, eliminaCorriere]}
                ]" :result="result" name="Corrieri" @searchPage="searchPage" @orderBy="orderBy"></Table>
            </div>
        </div>
    </div>
</template>
<script>
import GeneralFilter from './filters/GeneralFilter.vue';
import Table from './utility/Table.vue';
import SetUser from './utility/SetUser.vue';

export default {
    components: {
        GeneralFilter,
        Table,
        SetUser
    },
    data() {
        return {
            result: undefined,
            data: undefined,
            lastSearch: undefined,
            order_by: undefined,
            corriere: undefined,
            newUser: undefined
        }
    },
    methods: {
        async scegliCorriere(obj) {
            await this.storeLogTime(async () => {
                let res;
                if(obj.new) {
                    res = await this.handleCallback(() => this.axios.post(this.$store.getters.uri + '/create-user-courier-template', { codice_corriere: obj.CORRIERE }));
                } else {
                    res = await this.handleCallback(() => this.axios.post(this.$store.getters.uri + '/finduser', { id: obj.id }));
                }
                this.corriere = res;
                this.newUser = obj.new;
                return res;
            });
        },
        async eliminaCorriere(obj) {
            await this.storeLogTime(async () => {
                const res = await this.handleCallback(() => this.axios.post(this.$store.getters.uri + '/removeuser', { id: obj.id }), true);
                this.searchPage(this.lastSearch);
                return res;
            });
        },
        search(data) {
            this.data = data;
            this.lastSearch = this.$store.getters.uri + '/corrieri';
            this.searchPage(this.lastSearch);
        },
        async searchPage(url) {
            await this.storeLogTime(async () => {
                let res = null;
                if (url) {
                    this.lastSearch = url;
                    this.data.order_by = this.order_by;
                    res = await this.handleCallback(() => this.axios.post(url, this.data));
                    this.$set(this, 'result', res);
                }
                return res;
            });
        },
        orderBy(ord) {
            this.order_by = ord;
            this.searchPage(this.lastSearch);
        },
        clear() {
            this.corriere = undefined;
        },
        async createUser(user, repeat_password) {
            await this.storeLogTime(async () => {
                let res = null;
                if (user.password === repeat_password) {
                    res = await this.handleCallback(() => this.axios.post(this.$store.getters.uri + '/setuser', { data: user }), true);
                    if (res) {
                        this.searchPage(this.lastSearch);
                        this.clear();
                    }
                } else
                    this.$emit('notify', false, this.__('Password differenti'));
                return res;
            });
        }
    }
}
</script>