<template>
    <div v-if="notifications.length > 0" class="fixed z-40 inset-0 overflow-y-auto" aria-labelledby="modal-title"
         role="dialog" aria-modal="true" @click="closeNotification">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div
                class="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div v-for="(notification, index) in notifications" :key="index"
                     :class="notification.status ? 'bg-green-100 border-green-400 text-green-700' : 'bg-red-100 border-red-400 text-red-700'"
                     class="flex inline border px-4 rounded relative" role="alert">

                    <span class="mr-2 font-bold m-2">{{ notification.title }}{{ notification.message ? ':' : '' }} </span>
                    <span class="font-bold m-2" v-if="notification.message">{{ notification.message }}</span>
                    <span @click="deleteNotification(index)" class="absolute top-0 bottom-0 right-0 px-4 m-2">
                        <svg :class="notification.status ? 'text-green-500' : 'text-red-500'"
                             class="fill-current h-6 w-6"
                             role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path
                                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
                        </svg>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        notifications: Array,
    },
    methods: {
        deleteNotification(index) {
            this.notifications.splice(index, 1);
        },
        closeNotification(event) {
            // Chiude la notifica solo se l'utente ha cliccato al di fuori dell'elemento del pop-up
            if (!event.target.closest(".inline-block")) {
                this.notifications.splice(0, this.notifications.length);
            }
        },
    },
};
</script>


