<template>
    <div v-if="extended" class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
        <div class="sm:flex sm:items-center sm:ml-6" v-for="(item, index) in $store.state.menuItems" :key="index">
            <div v-if="item.sub_menu_items && item.sub_menu_items.length > 0" class="ml-3 relative">
                <button @click="setFlag(index, !dropdownStates[index])" type="button"
                    class="hover:bg-gray-200 flex text-sm focus:outline-none rounded-md"
                    :id="'menu-dropdown-'+index" aria-expanded="false" aria-haspopup="true">
                    <span class="inline-flex rounded-md">
                        <div class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 hover:text-gray-700 focus:outline-none transition">
                            {{item.name}}
                            <svg class="ml-2 -mr-0.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                fill="currentColor">
                                <path fill-rule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clip-rule="evenodd" />
                            </svg>
                        </div>
                    </span>
                </button>
                <div v-show="dropdownStates[index]" @click="setFlag(index, false)"
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu" aria-orientation="vertical" :aria-labelledby="'menu-dropdown-'+index" tabindex="-1">
                    <div v-for="(submenu, index2) in item.sub_menu_items" :key="index2"
                        class="block px-4 py-2 text-xs text-gray-400">
                        <router-link :to="submenu.link" class="block px-4 py-2 text-sm text-gray-700"
                            role="menuitem" tabindex="-1" :id="'submenu-dropdown-'+index+'-'+index2">
                            {{ submenu.name }}
                        </router-link>
                    </div>
                </div>
            </div>
            <router-link v-else @click="setFlag(-1, false)" :to="item.link" class="hover:bg-gray-200 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 hover:text-gray-700 focus:outline-none transition">
                {{ item.name }}
            </router-link>
        </div>
    </div>
    <div v-else>
        <div class="sm:flex sm:items-center sm:ml-6" v-for="(item, index) in $store.state.menuItems" :key="index">
            <div>
                <div class="block px-4 py-2 font-medium text-gray-500" v-if="item.sub_menu_items && item.sub_menu_items.length > 0">
                    {{item.name}}
                </div>
                <router-link class="block px-4 py-2 text-sm text-gray-900" v-else :to="item.link">
                    {{ item.name }}
                </router-link>
            </div>
            <div class="block px-4 py-2 text-sm text-gray-900">
                <div v-for="(submenu, index2) in item.sub_menu_items" :key="index2"
                    class="block px-4 py-2 text-xs text-gray-400">
                    <router-link :to="submenu.link" class="block px-4 py-2 text-sm text-gray-700"
                        role="menuitem" tabindex="-1" :id="'submenu-dropdown-'+index+'-'+index2">
                        {{ submenu.name }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            extended: Boolean
        },
        data() {
            return {
                dropdownStates: []
            }
        },
        methods: {
            setFlag(index, value) {
                for (let i in this.dropdownStates)
                    this.$set(this.dropdownStates, i, false);
                this.$set(this.dropdownStates, index, value);
            },
            close(e) {
                if (!this.$el.contains(e.target)) {
                    for (let i in this.dropdownStates)
                        this.setFlag(i, false);
                }
            }
        },
        mounted() {
            document.addEventListener('click', this.close);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.close);
        }
    }
</script>