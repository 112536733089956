var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-1 min-w-0"},[_c('h2',{staticClass:"mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight"},[_vm._v("\n        "+_vm._s(_vm.__("Visualizza PESATE"))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"py-12"},[_c('div',{staticClass:"mx-auto sm:px-6"},[_c('GeneralFilter',{attrs:{"in_rows":[
                [
                    {
                        type: 'date',
                        title: _vm.__('Data inizio'),
                        filter: {
                            operator: '>',
                            field: 'created_at',
                            type: 'text',
                            value: _vm.fromDate
                        },
                        text: _vm.fromDate
                    },
                    {
                        type: 'date',
                        title: _vm.__('Data fine'),
                        filter: {
                            operator: '<=   ',
                            field: 'created_at',
                            type: 'text',
                            value: _vm.toDate
                        },
                        text: _vm.toDate
                    }
                ]
            ],"all":true,"url":_vm.$store.getters.uri+'/params-storicopesate'},on:{"search":_vm.search}}),_vm._v(" "),_c('Table',{attrs:{"items":[
                {label: _vm.__('Data'), value: 'created_at', type: 'date', cssclass: ''},
                {label: _vm.__('ID Spedizione'), value: 'package', type: 'text', cssclass: ''},
                {label: _vm.__('Peso'), value: 'weight', type: 'text', cssclass: 'text-right'},
                {label: _vm.__('Terminale'), value: 'devicenumber', type: 'text', cssclass: ''},
                {label: _vm.__('Baia'), value: 'bay', type: 'text', cssclass: ''},
                {label: _vm.__('Riferimento Baia'), value: 'qrcode_extended', type: 'text', cssclass: ''}
            ],"result":_vm.result,"name":"Visualizza_PESATE","exportTable":true},on:{"searchPage":_vm.searchPage,"orderBy":_vm.orderBy}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }