import 'es6-promise/auto';
import axios from 'axios';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import VueRouter from 'vue-router';
import JsonExcel from "vue-json-excel";
import App from './views/App';
import router from './router';
import store from './store';
import Dictionary from './Dictionary';
import ExportExcel from './ExportExcel';
import HandleCallback from './HandleCallback';

import {useDebouncedRef} from "./debounce";

// Set Vue globally
window.Vue = Vue;
// Set Vue router
Vue.router = router;
Vue.use(VueRouter);

// Set Vue authentication
Vue.use(VueAxios, axios);
axios.defaults.baseURL = `/api`;

// Import
Vue.mixin(Dictionary);
Vue.mixin(ExportExcel);
Vue.mixin(HandleCallback);

// Load App
Vue.component('app', App);

Vue.component("downloadExcel", JsonExcel);

new Vue({
  store,
  router
}).$mount('#app');
