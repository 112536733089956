<template>
    <div class="bg-white overflow-hidden shadow-xl sm:rounded-lg">
        <ShowHide :shown="shown" @show="show"></ShowHide>
        <div v-if="shown">
            <form class="p-10" @submit.prevent="search">
                <div v-for="(components, indexComps) in rows" :key="indexComps" class="flex flex-wrap">
                    <div v-for="(comp, indexComp) in components" :key="indexComp"
                        :class="'px-3 my-4 w-full md:w-1/'+components.length">
                        <AutoComplete v-if="comp.type === 'auto_complete'" :title="comp.title" :url="url"
                            :text="comp.text" :selectedOption="comp.filter"
                            :autoSelectOneOption="comp.autoSelectOneOption === true"
                            :mapOptionTitle="(option) => option.length > 0 ? option.map(x => x.value).reduce((x,y) => x + ' ' + y) : ''"
                            @chosen="(chosen) => {
                                select(indexComps, indexComp, 'text', chosen.text);
                                select(indexComps, indexComp, 'filter', chosen.selectedOption);
                            }" :searchOptions="(url, word) => searchOptions(url, comp.params, word)"></AutoComplete>
                        <SelectOption v-else-if="comp.type === 'select_option'" :src="comp.src" :title="comp.title"
                            :url="url" :selectedOption="comp.filter" :mapOptionTitle="comp.mapOptionTitle"
                            :header="comp.header" :searchOptions="(url) => searchOptions(url, [comp.field])"
                            @chosen="(chosen) => select(indexComps, indexComp, 'filter', chosen)" :params="comp.params">
                        </SelectOption>
                        <div v-else-if="comp.type === 'text_input'">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                {{comp.title}}
                            </label>
                            <input
                                class="text-xs appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                style="height: 33px;" v-model="comp.text" :type='comp.type' :min='comp.min'
                                :max='comp.max' v-on:blur="(evt) => select(indexComps, indexComp, 'filter', {
                                  operator: comp.filter.operator,
                                  field: comp.filter.field,
                                  type: comp.filter.type
                                })">
                        </div>
                        <div v-else>
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                {{comp.title}}
                            </label>
                            <input
                                class="text-xs appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                v-model="comp.text" :type='comp.type' :min='comp.min' :max='comp.max'>
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap">
                    <div class="md:w-1/3 px-3 my-4 w-full">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs w-full font-bold mb-2">
                            {{ __('Risultati per pagina')}}
                        </label>
                        <div>
                            <select name="risultati_pagina" v-model="data.risultati_pagina" class="w-full">
                                <option type="radio" name="risultati_pagina" id="risultati_pagina" value="50">50
                                </option>
                                <option type="radio" name="risultati_pagina" id="risultati_pagina" value="100">100
                                </option>
                                <option type="radio" name="risultati_pagina" id="risultati_pagina" value="150">150
                                </option>
                                <option v-if="all" type="radio" name="risultati_pagina" id="risultati_pagina"
                                    value="all">tutti</option>
                            </select>
                        </div>
                    </div>
                    <div class="md:w-2/3 px-3 flex inline justify-end items-center my-4 w-full">
                        <div class="mt-4 text-center cursor-pointer bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded"
                            @click="clear()">
                            {{ __('Azzera Opzioni di Ricerca')}}
                        </div>
                        <button
                            class="ml-4 mt-4 focus:outline-none text-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded"
                            type="button" @click="submit()">
                            {{ __('Filtra')}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import AutoComplete from './AutoComplete.vue';
import SelectOption from './SelectOption.vue';
import ShowHide from './ShowHide.vue';

export default {
    components: {
        AutoComplete,
        SelectOption,
        ShowHide
    },
    props: {
        in_rows: Array,
        url: String,
        all: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            rows: Object.assign({}, this.in_rows),
            data: {
                risultati_pagina: 50
            },
            shown: true,
            arrayTypes: ["auto_complete", "select_option"]
        }
    },
    created() {
        this.data.risultati_pagina = this.all ? 'all' : 50;
        for (let r in this.rows)
            for (let e in this.rows[r])
                if (this.arrayTypes.includes(this.rows[r][e].type))
                    this.rows[r][e].filter = [];
        this.$set(this, 'rows', Object.assign({}, this.rows));
    },
    computed: {
        filters() {
            let f = [];
            for (let r in this.rows)
                for (let e in this.rows[r])
                    if (this.rows[r][e].filter) {
                        let filter = undefined;
                        if (Array.isArray(this.rows[r][e].filter) && this.rows[r][e].filter.length > 0)
                            filter = Object.assign({}, this.rows[r][e].filter);
                        else if (this.rows[r][e].text) {
                            this.rows[r][e].filter.value = this.rows[r][e].text;
                            filter = Object.assign({}, [this.rows[r][e].filter]);
                        }
                        if (filter) {
                            if (this.rows[r][e].unique)
                                return [filter];
                            else
                                f.push(filter);
                        }
                    }
            return f;
        }
    },
    methods: {
        async searchOptions(url, params, word = undefined) {
            let res;
            await this.storeLogTime(async () => {
                res = await this.handleCallback(() => this.axios.post(url, { word: word, params: params }), false, true, false)
                return res;
            });
            return res.value || res;
        },
        select(indexComps, indexComp, name, chosen) {
            this.rows[indexComps][indexComp][name] = chosen;
            this.$set(this, 'rows', Object.assign({}, this.rows));
        },
        search() {
            this.data.filters = this.filters;

            for (let d in this.data.filters) {
                if (this.data.filters[d][0].operator == 'like') {
                    this.data.filters[d][0].value = '%' + this.data.filters[d][0].value + '%';
                }
            }

            this.$emit('search', Object.assign({}, this.data));
        },
        clear() {
            for (let r in this.rows)
                for (let e in this.rows[r]) {
                    if (Array.isArray(this.rows[r][e].filter))
                        this.rows[r][e].filter = [];
                    this.rows[r][e].text = undefined;
                }
            this.$set(this, 'rows', Object.assign({}, this.rows));
        },
        submit() {
            this.search();
        },
        show(shown) {
            this.shown = shown;
        }
    }
}
</script>
