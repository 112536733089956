<template>
    <div class="flex-1 min-w-0">
        <div>
            <h2 class="mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight">
                {{ __("Gestisci Filiali") }}
            </h2>
            <div class="mx-auto sm:px-6">
                <label class="block uppercase tracking-wide text-gray-700 font-bold m-4">
                    {{ __("Crea") }}
                </label>
                <form class="p-6 m-6 border border-black rounded" @submit.prevent="createFiliale()">
                    <div class="flex flex-wrap py-2 justify-around">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            {{ __("Id") }}
                        </label>
                        <input
                            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            v-model="id" />
                    </div>
                    <div class="flex flex-wrap py-2 justify-around">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            {{ __("Ora Limite") }}
                        </label>
                        <input
                            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            v-model="ora_limite" type="time" />
                    </div>
                    <div class="flex flex-wrap pt-2 justify-between">
                        <div class="flex flex-wrap pt-2">
                            <button type="submit"
                                class="w-full m-auto inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                                {{ __("Crea") }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="mx-auto sm:px-6">
                <label class="block uppercase tracking-wide text-gray-700 font-bold m-4">
                    {{ __("Modifica") }}
                </label>
                <div v-for="(fil, i) in filiali" :key="i">
                    <form class="p-6 m-6 border border-black rounded" @submit.prevent="setFiliale(i)">
                        <label class="block uppercase tracking-wide text-gray-700 font-bold mb-2"> {{ __("Filiale ")+
                        fil.id }}</label>
                        <div class="flex flex-wrap py-2 justify-around">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                {{ __("Ora Limite") }}
                            </label>
                            <input
                                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                v-model="fil.ora_limite" type="time" />
                        </div>
                        <div class="flex flex-wrap pt-2 justify-between">
                            <div class="flex flex-wrap pt-2">
                                <button type="submit"
                                    class="w-full m-auto inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                                    {{ __("Aggiorna") }}
                                </button>
                                <div @click="deleteFiliale(i)"
                                    class="w-full cursor-pointer m-auto inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                                    {{ __("Elimina") }}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            id: '',
            ora_limite: '',
            filiali: []
        };
    },
    async created() {
        await this.init();
    },
    methods: {
        async init() {
            await this.storeLogTime(async () => {
                const res = await this.handleCallback(
                    () =>
                        this.axios.post(
                            "/admin/filiali"
                        ),
                );
                this.filiali = res.value || res;;
                this.filiali.forEach(f => {
                    const ora = (f.ora_limite + '').padStart(4, '0');
                    f.ora_limite = ora.substring(0, 2) + ':' + ora.substring(2, 4);
                });
                return res;
            });
        },
        async deleteFiliale(i) {
            await this.storeLogTime(async () => {
                const res = await this.handleCallback(
                    () =>
                        this.axios.delete(
                            "/admin/delete-filiale?id=" +
                            this.filiali[i].id
                        ),
                    true
                );
                await this.init();
                return res;
            });
        },
        async setFiliale(i) {
            await this.storeLogTime(async () => {
                this.filiali[i].ora_limite = this.filiali[i].ora_limite.replace(':', '');
                const res = await this.handleCallback(
                    () =>
                        this.axios.post(
                            "/admin/set-filiale",
                            this.filiali[i]
                        ),
                    true
                );
                await this.init();
                return res;
            });
        },
        async createFiliale() {
            await this.storeLogTime(async () => {
                let res = null;
                if (this.id.length > 0 && this.ora_limite.length > 0) {
                    res = await this.handleCallback(
                        () =>
                            this.axios.post(
                                "/admin/create-filiale",
                                { id: this.id, ora_limite: this.ora_limite.replace(':', '') }
                            ),
                        true
                    );
                    this.id = '';
                    this.ora_limite = '';
                    this.init();
                } else {
                    this.$emit('notify', false, 'Campi mancanti.');
                }
                return res;
            });
        },
    }
};
</script>
