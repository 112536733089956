<template>
    <div class="flex-1 min-w-0">
        <h2 class="mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight">{{ __("Spedizioni") }}</h2>
        <div class="py-12">
            <div class="mx-auto sm:px-6">
                <GeneralFilter :in_rows="[
                    [
                        {
                            type: 'date',
                            title: __('Data inizio'),
                            filter: {
                                operator: '>=',
                                field: 'T05_BODTB',
                                type: 'data'
                            },
                            min: minDate,
                            max: maxDate,
                            text: fromDate
                        },
                        {
                            type: 'date',
                            title: __('Data fine'),
                            filter: {
                                operator: '<=',
                                field: 'T05_BODTB',
                                type: 'data'
                            },
                            min: minDate,
                            max: maxDate,
                            text: maxDate
                        }
                    ],
                    [
                        {
                            type: 'text_input',
                            title: __('Nome mittente'),
                            filter: {
                                operator: 'like',
                                field: 'T05_BOMIT',
                                type: 'text'
                            },
                        },
                        {
                            type: 'text_input',
                            title: __('Riferimento mittente'),
                            filter: {
                                operator: 'like',
                                field: 'T05_BORMI',
                                type: 'text'
                            }
                        },
                        {
                            type: 'select_option',
                            title: __('Provincia di partenza'),
                            src: 'remote',
                            mapOptionTitle: option => option[0].value,
                            field: 'T05_BOMPR'
                        }
                    ],
                    [
                        {
                            type: 'text_input',
                            title: __('Nome destinatario'),
                            filter: {
                                operator: 'like',
                                field: 'T05_BODIT',
                                type: 'text'
                            }
                        },
                        {
                            type: 'text_input',
                            title: __('Località destinatario'),
                            filter: {
                                operator: 'like',
                                field: 'T05_BODLO',
                                type: 'text'
                            }
                        },
                        {
                            type: 'select_option',
                            title: __('Provincia destinatario'),
                            src: 'remote',
                            mapOptionTitle: option => option[0].value,
                            field: 'T05_BODPR'
                        }
                    ],
                    [
                        {
                            title: __('Pratica'),
                            type: 'select_option',
                            src: 'local',
                            mapOptionTitle: option => option[0].label,
                            header: __('Tutti'),
                            params: [
                                [
                                    {
                                        operator: '>=',
                                        field: 'T05_BOT33',
                                        type: 'text',
                                        value: '0',
                                        label: __('Solo Pratica')
                                    }
                                ],
                                [
                                    {
                                        operator: '<',
                                        field: 'T05_BOT33',
                                        type: 'text',
                                        value: '0',
                                        label: __('Escludi Pratica')
                                    }
                                ]
                            ]
                        },
                        {
                            title: __('Contrassegno'),
                            type: 'select_option',
                            src: 'local',
                            mapOptionTitle: option => option[0].label,
                            header: __('Tutti'),
                            params: [
                                [
                                    {
                                        operator: '>',
                                        field: 'T05_CONTRASSEG',
                                        type: 'text',
                                        value: '0',
                                        label: __('Solo Contrassegno')
                                    }
                                ],
                                [
                                    {
                                        operator: '<=',
                                        field: 'T05_CONTRASSEG',
                                        type: 'text',
                                        value: '0',
                                        label: __('Escludi Contrassegno')
                                    }
                                ]
                            ]
                        },
                        {
                            title: __('Giacenza'),
                            type: 'select_option',
                            src: 'local',
                            mapOptionTitle: option => option[0].label,
                            header: __('Tutti'),
                            params: [
                                [
                                    {
                                        operator: '>=',
                                        field: 'T05_BOT18',
                                        type: 'text',
                                        value: '0',
                                        label: __('Solo Giacenza')
                                    }
                                ],
                                [
                                    {
                                        operator: 'REGEX',
                                        field: 'concatenated_T02_TAPRF',
                                        type: 'text',
                                        value: ',18,',
                                        label: __(
                                            'Aperta senza disposizioni'
                                        )
                                    },
                                    {
                                        operator: 'NOT REGEX',
                                        field: 'concatenated_T02_TAPRF',
                                        type: 'text',
                                        value: ',20,|,25,',
                                        label: __(
                                            'Aperta senza disposizioni'
                                        )
                                    }
                                ],
                                [
                                    {
                                        operator: 'REGEX',
                                        field: 'concatenated_T02_TAPRF',
                                        type: 'text',
                                        value: ',25,',
                                        label: __('Aperta con disposizioni')
                                    },
                                    {
                                        operator: 'NOT REGEX',
                                        field: 'concatenated_T02_TAPRF',
                                        type: 'text',
                                        value: ',20,',
                                        label: __('Aperta con disposizioni')
                                    }
                                ],
                                [
                                    {
                                        operator: 'REGEX',
                                        field: 'concatenated_T02_TAPRF',
                                        type: 'text',
                                        value: ',20,',
                                        label: __('Chiusa')
                                    }
                                ],
                                [
                                    {
                                        operator: '<',
                                        field: 'T05_BOT18',
                                        type: 'text',
                                        value: '0',
                                        label: __('Escludi Giacenza')
                                    }
                                ]
                            ]
                        }
                    ]
                ]" :url="$store.getters.uri + '/params-spedizioni'" @search="search"></GeneralFilter>
                <Table :items="[
                    {
                        label: __('Sped.'),
                        value: 'id_spedizione',
                        type: 'combination',
                        map: obj =>
                            (obj['T05_BOANB'] + '').padStart(2, '0') +
                            (obj['T05_BOFBO'] + '').padStart(2, '0') +
                            (obj['T05_BOKEY'] + '').padStart(7, '0'),
                        popupTitle: obj =>
                            'ID Spedizione: ' +
                            (obj['T05_BOANB'] + '').padStart(2, '0') +
                            (obj['T05_BOFBO'] + '').padStart(2, '0') +
                            (obj['T05_BOKEY'] + '').padStart(7, '0'),
                        subItems: ['T05_BOANB', 'T05_BOFBO', 'T05_BOKEY'],
                        clickable: true,
                        onclick: onClickEvent()
                    },
                    {
                        label: __('Rif. Mittente'),
                        value: 'T05_BORMI',
                        type: 'text'
                    },
                    {
                        label: __('Mittente'),
                        value: 'T05_BOMIT',
                        type: 'text'
                    },
                    {
                        label: __('Destinatario'),
                        value: 'T05_BODIT',
                        type: 'text'
                    },
                    {
                        label: __('Provincia Destinatario'),
                        value: 'T05_BODPR',
                        type: 'text',
                    }
                    ,
                    {
                        label: __('Data Sped'),
                        value: 'T05_BODTB',
                        type: 'text'
                    },
                    {
                        label: __('Giacenza'),
                        value: 'giacenza',
                        type: 'combination',
                        denyOrd: true,
                        map: this.mapGiacenza(),
                        subItems: ['EVENTI']
                    },
                    {
                        label: __('Pratica'),
                        value: 'T05_BOT33',
                        type: 'text'
                    },
                    {
                        label: __('Prevista Consegna'),
                        value: 'prevista_consegna',
                        type: 'combination',
                        denyOrd: true,
                        map: this.mapPrevConsegna(),
                        subItems: ['T05_BODCV', 'EVENTI']
                    },
                    {
                        label: __('Consegna Effettiva'),
                        value: 'consegna_effettiva',
                        type: 'combination',
                        denyOrd: true,
                        map: this.mapConsegnaEffettiva(),
                        subItems: ['T01_TBDAT', 'EVENTI']
                    },
                    {
                        label: __('POD'),
                        type: 'buttons',
                        enabled: obj => obj['POD_EXISTS'],
                        onclick: [clickPod],
                        denyOrd: true,
                        values: e => [
                            '<svg xmlns=\'http://www.w3.org/2000/svg\' class=\'h-6 w-6\' fill=\'none\' viewBox=\'0 0 24 24\' stroke=\'currentColor\'> <path stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'2\' d=\'M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z\' /> </svg>'
                        ]
                    }
                ]" :result="result" name="Spedizioni" @searchPage="searchPage" @orderBy="orderBy"
                    @setLoading="status => $emit('setLoading', status)" @notify="(status, message) => $emit('notify', status, message)
                        "></Table>
            </div>
        </div>
    </div>
</template>
<script>
import GeneralFilter from "./filters/GeneralFilter.vue";
import Table from "./utility/Table.vue";


export default {
    components: {
        GeneralFilter,
        Table
    },
    created() {
        this.order_by.fields.push("T05_BODTB");
        let max = new Date();
        let from = new Date();
        let min = new Date();
        let offset = this.$store.state.user.storico_gg;
        min.setDate(new Date().getDate() - offset);
        from.setDate(new Date().getDate() - 15);
        this.minDate =
            offset == 0 ? undefined : min.toISOString().split("T")[0];
        this.fromDate = from.toISOString().split("T")[0];
        this.maxDate = max.toISOString().split("T")[0];

        this.MIX_DHL_TRACKING_URL = process.env.MIX_DHL_TRACKING_URL;

    },
    data() {
        return {
            minDate: undefined,
            fromDate: undefined,
            maxDate: undefined,
            result: undefined,
            data: undefined,
            lastSearch: undefined,
            order_by: {
                fields: [],
                ord: "desc"
            }
        };
    },
    methods: {
        mapConsegnaEffettiva() {
            return obj => {
                const e = obj['EVENTI'].find(
                    e =>
                        e['T02_TAPRF'] == '05' &&
                        e['T02_TAPRO'] == '123'
                );
                return e != null ? e['T01_TBDAT'] : '';
            }
        },
        mapPrevConsegna() {
            return obj => {
                const e = obj['EVENTI'].find(
                    e =>
                        e['T02_TAPRF'] == '05' &&
                        e['T02_TAPRO'] == '035'
                );
                return e != null
                    ? e['T01_TBDAT']
                    : obj['T05_BODCV'];
            }
        },
        mapGiacenza() {
            return obj => {
                if (
                    obj['EVENTI'].find(
                        e => e['T02_TAPRF'] == 20
                    )
                )
                    return 'Chiusa';
                else if (
                    obj['EVENTI'].find(
                        e => e['T02_TAPRF'] == 25
                    )
                )
                    return 'Aperta con disposizioni';
                else if (
                    obj['EVENTI'].find(
                        e => e['T02_TAPRF'] == 18
                    )
                )
                    return 'Aperta senza disposizioni';
                else return '';
            }
        },
        GetDHLTrackingUrl(dhlTrackingId) {
           const stringWithPlaceholders = this.MIX_DHL_TRACKING_URL;
           const replacements = {
               dhl_tracking_id: dhlTrackingId
           }
           return stringWithPlaceholders.replace(/{(\w+)}/g,
               (placeholderWithDelimiters, placeholderWithoutDelimiters) =>
                   replacements[placeholderWithoutDelimiters] || placeholderWithDelimiters
           );
        },
        onClickEvent() {
            return async obj =>
                [
                    '-',
                    `<b>Riferimento mittente</b>: ${obj['T05_BORMI']}`,
                    `<b>Mittente</b>: ${obj['T05_BOMIT']}`,
                    `<b>Mittente indirizzo</b>: ${obj['T05_BOMIN']} (${obj['T05_BOMPR']})`,
                    `<b>Destinatario</b>: ${obj['T05_BODIT']}`,
                    `<b>Destinatario indirizzo</b>: ${obj['T05_BODIN']} (${obj['T05_BODPR']})`,
                    `<b>Data Sped</b>: ${obj['T05_BODTB']}`,
                    this.mapGiacenza()(obj).length > 0 ? `<b>Giacenza</b>: ${this.mapGiacenza()(obj)}` : '',
                    obj['T05_BOT33'].length > 0 ? `<b>Pratica</b>: ${obj['T05_BOT33']}` : '',
                    this.mapPrevConsegna()(obj).length > 0 ? `<b>Prevista consegna</b>: ${this.mapPrevConsegna()(obj)}` : '',
                    this.mapConsegnaEffettiva()(obj).length > 0 ? `<b>Consegna effettiva</b>: ${this.mapConsegnaEffettiva()(obj)}` : '',
                    '-',
                    `<b>EVENTI</b>`,
                    ...(await obj.EVENTI.filter(
                        e => e["T02_TAPRF"] != "05" || e["T02_TAPRO"] != "130"
                    ).map(
                        e =>
                            e["T01_TBDAT"] +
                            " - " +
                            e["T02_DESCRIZION"] +
                            (e["T04_DESCR_ZONA"].length > 0
                                ? ": <i>" + e["T04_DESCR_ZONA"] + "</i>"
                                : "")
                    )
                    ),
                    //DHL TRACKING
                    this.$store.state.show_dhl_tracking_link ? (await obj.EVENTI.find(e => e["T02_TAPRF"] == "05" && e["T02_TAPRO"] == "137") ? '<div id="spedition_detail_dhl_tracking"><a target="_blank" href="' + this.GetDHLTrackingUrl(await obj.EVENTI.find(e => e["T02_TAPRF"] == "05" && e["T02_TAPRO"] == "137")["T01_TBDES"]) +'"><img src="img/dhlLogo.png">Visualizza tracking</a></div> '  : '') : ''
                ];
        },
        search(data) {
            this.data = data;
            this.lastSearch = this.$store.getters.uri + "/spedizioni";
            this.searchPage(this.lastSearch);
        },
        async searchPage(url) {
            await this.storeLogTime(async () => {
                let res = null;
                if (url) {
                    //console.log('this.data',this.data);
                    this.lastSearch = url;
                    this.data.order_by = this.order_by;
                    res = await this.handleCallback(() =>
                        this.axios.post(url, this.data)
                    );
                    this.$set(this, "result", res);
                }
                return res;
            });
        },
        orderBy(ord) {
            this.order_by = ord;
            this.searchPage(this.lastSearch);
        },
        clickPod(obj) {
            this.handleCallback(
                () =>
                    this.axios.post(this.$store.getters.uri + "/pod", { spedizione: obj }),
                false,
                false
            ).then(flag => {
                if (flag) {
                    this.handleCallback(() =>
                        this.axios.post(this.$store.getters.uri + "/pod", { spedizione: obj }, { responseType: "arraybuffer" })
                            .then(res => {
                                var file = new Blob([res.data], {
                                    type: "application/pdf"
                                });
                                var fileURL = URL.createObjectURL(file);
                                window.open(fileURL);
                            })
                    );
                } else {
                    this.$emit("notify", false, "Impossibile aprire file POD");
                }
            });
        }
    }
};
</script>
