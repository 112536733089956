<template>
    <div class="flex-1 min-w-0">
        <h2 class="mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight">
            {{ __("Storico POD") }}
        </h2>
        <div class="py-12">
            <div class="mx-auto sm:px-6">
                <GeneralFilter :in_rows="[
                    [
                        {
                            type: 'date',
                            title: __('Data inizio'),
                            filter: {
                                operator: '>',
                                field: 'dateTime',
                                type: 'text',
                                value: fromDate
                            },
                            text: fromDate
                        },
                        {
                            type: 'date',
                            title: __('Data fine'),
                            filter: {
                                operator: '<',
                                field: 'dateTime',
                                type: 'text',
                                value: toDate
                            },
                            text: toDate
                        }
                    ],
                    [
                        {
                            type: 'auto_complete',
                            title: __('Codice spedizione'),
                            params: ['spedizione']
                        },
                        {
                            type: 'auto_complete',
                            title: __('Nome file'),
                            params: ['fileName']
                        }
                    ]
                ]" :all="true" :url="$store.getters.uri + '/params-storicopod'" @search="search"></GeneralFilter>
                <Table :items="[
                    {
                        label: __('Spedizione'),
                        value: 'spedizione',
                        type: 'text'
                    },
                    {
                        label: __('Data Visualizzazione'),
                        value: 'dateTime',
                        type: 'text'
                    },
                    {
                        label: __('Nome File'),
                        value: 'fileName',
                        type: 'text'
                    },
                    {
                        label: __('POD'),
                        type: 'buttons',
                        onclick: [clickPod],
                        denyOrd: true,
                        values: e => [
                            '<svg xmlns=\'http://www.w3.org/2000/svg\' class=\'h-6 w-6\' fill=\'none\' viewBox=\'0 0 24 24\' stroke=\'currentColor\'> <path stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'2\' d=\'M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z\' /> </svg>'
                        ]
                    }
                ]" :result="result" name="Storico_POD" @searchPage="searchPage" @orderBy="orderBy"></Table>
            </div>
        </div>
    </div>
</template>
<script>
import GeneralFilter from "./filters/GeneralFilter.vue";
import Table from "./utility/Table.vue";

export default {
    components: {
        GeneralFilter,
        Table
    },
    data() {
        return {
            result: undefined,
            data: undefined,
            lastSearch: undefined,
            order_by: {
                fields: ["dateTime"],
                ord: "desc"
            },
            fromDate: undefined,
            toDate: undefined
        };
    },
    created() {
        let now = new Date();
        let minDate = new Date(now.getFullYear(), now.getMonth() - 1, 2);
        let maxDate = new Date(now.getFullYear(), now.getMonth(), 1);
        this.fromDate = minDate.toISOString().split("T")[0];
        this.toDate = maxDate.toISOString().split("T")[0];
    },
    methods: {
        search(data) {
            this.data = data;
            this.lastSearch = this.$store.getters.uri + "/storicopod";
            this.searchPage(this.lastSearch);
        },
        async searchPage(url) {
            await this.storeLogTime(async () => {
                let res = null;
                if (url) {
                    this.lastSearch = url;
                    this.data.order_by = this.order_by;
                    res = await this.handleCallback(() =>
                        this.axios.post(url, this.data)
                    );
                    this.$set(this, "result", res);
                }
                return res;
            });
        },
        orderBy(ord) {
            this.order_by = ord;
            this.searchPage(this.lastSearch);
        },
        clickPod(obj) {
            this.storeLogTime(() => {
                return this.handleCallback(
                    () =>
                        this.axios.post(this.$store.getters.uri + "/pod", {
                            spedizione: obj
                        }),
                    false,
                    false
                ).then(flag => {
                    if (flag) {
                        return this.handleCallback(() =>
                            this.axios
                                .post(
                                    this.$store.getters.uri + "/pod",
                                    { spedizione: obj },
                                    { responseType: "arraybuffer" }
                                )
                                .then(res => {
                                    var file = new Blob([res.data], {
                                        type: "application/pdf"
                                    });
                                    var fileURL = URL.createObjectURL(file);
                                    window.open(fileURL);
                                    return [flag, res];
                                })
                        );
                    } else {
                        this.$emit("notify", false, "Impossibile aprire file POD");
                        return flag;
                    }
                });
            });
        }
    }
};
</script>
