<template>
    <div class="flex-1 min-w-0">
      <div class="flex flex-wrap w-full">
        <div v-for="(item, index) in menuItems" :key="index" class="md:p-4 md:w-1/2 p-2 w-full">
          <router-link :to="item.link" class="flex items-center justify-center h-40 text-center cursor-pointer hover:bg-red-600 text-white border bg-red-500 rounded">
            <label class="text-xl">{{item.name}}</label>
          </router-link>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      menuItems: []
    }
  },
  async created() {
    let orig = this.$store.state.menuItems;
    orig.forEach(v => {
      if(v.sub_menu_items.length == 0)
        this.menuItems.push(v);
      else
        v.sub_menu_items.forEach(e => this.menuItems.push(e));
      });
  }
}
</script>