var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-1 min-w-0"},[_c('h2',{staticClass:"mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight"},[_vm._v("\n        "+_vm._s(_vm.__("Anagrafiche Corrieri"))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"mx-auto sm:px-6 py-6"},[(_vm.corriere)?_c('div',[_c('SetUser',{attrs:{"user":_vm.corriere,"role":"courier"},on:{"clear":_vm.clear,"createUser":_vm.createUser,"annulla":_vm.clear}})],1):_c('div',[_c('GeneralFilter',{attrs:{"in_rows":[
                [
                    {
                        type: 'auto_complete',
                        title: _vm.__('Codice corriere'), 
                        params: ['CORRIERE']
                    },
                    {
                        type: 'auto_complete',
                        title: _vm.__('Nome'), 
                        params: ['NOMECORR']
                    }
                ],
                [
                    {
                        type: 'auto_complete',
                        title: _vm.__('Filiale'), 
                        params: ['FOFIL']
                    },
                    {
                        type: 'auto_complete',
                        title: _vm.__('Codice Fornitore'), 
                        params: ['FOCOD']
                    }
                ],
                [
                    {
                        type: 'auto_complete',
                        title: _vm.__('Ragione Sociale'), 
                        params: ['FOBRE']
                    },
                    {
                        type: 'auto_complete',
                        title: _vm.__('P. IVA'), 
                        params: ['FOPIV']
                    }
                ],
                [
                    {
                        type: 'auto_complete',
                        title: _vm.__('Username'), 
                        params: ['username']
                    },
                    {
                        title: _vm.__('Stato Attivazione'),
                        type: 'select_option',
                        src: 'local',
                        mapOptionTitle: function (option) { return option[0].label; },
                        header: _vm.__('Tutti'),
                        params: [
                        [{
                            operator: 'IS NOT',
                            field: 'username',
                            type: 'text',
                            value: 'NULL',
                            label: _vm.__('Attivi')
                        }],
                        [{
                            operator: 'IS',
                            field: 'username',
                            type: 'text',
                            value: 'NULL',
                            label: _vm.__('Non Attivi')
                        }]
                        ]
                    }
                ]
            ],"url":_vm.$store.getters.uri+'/params-corrieri'},on:{"search":_vm.search}}),_vm._v(" "),_c('Table',{attrs:{"items":[
                {label: _vm.__('Codice'), value: 'codice', type: 'combination',
                    map: function (obj) { return obj['FOFIL']+obj['FOCOD']; },
                    subItems: ['FOFIL', 'FOCOD'],
                },
                {label: _vm.__('Codice Corriere'), value: 'CORRIERE', type: 'text'},
                {label: _vm.__('Nome'), value: 'NOMECORR', type: 'text'},
                {label: _vm.__('Ragione Sociale'), value: 'FOBRE', type: 'text'},
                {label: _vm.__('P. IVA'), value: 'FOPIV', type: 'text'},
                {label: _vm.__('Username'), value: 'username', type: 'text', denyOrd: true},
                {label: _vm.__('Scegli'), values: function (obj) { return obj.new ? ['Crea'] : ['Modifica', 'Elimina']; }, type: 'buttons', onclick: [_vm.scegliCorriere, _vm.eliminaCorriere]}
            ],"result":_vm.result,"name":"Corrieri"},on:{"searchPage":_vm.searchPage,"orderBy":_vm.orderBy}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }