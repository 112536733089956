<template>
    <div class="bg-white overflow-hidden shadow-xl sm:rounded-lg">
        <form class="p-10" @submit.prevent="createUser">
            <div class="flex flex-wrap">
                <div class="md:w-1/3 px-3 w-full my-4">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        {{ __('Utente')}}
                    </label>
                    <label class="uppercase text-xl font-bold">
                        {{user.username}}
                    </label>
                </div>
                <div class="md:w-1/3 px-3 w-full my-4">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        {{ __('Username')}}
                    </label>
                    <input
                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        v-model="user.username" type="text" placeholder="..." required>
                </div>
                <!--div class="md:w-1/3 px-3 w-full my-4">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    {{ __('Mail')}}
                                </label>
                                <input
                                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    v-model="user.mail" type="email" placeholder="...">
                            </div-->
                <div class="md:w-1/3 px-3 w-full my-4">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        {{ __('Storico gg')}}
                    </label>
                    <select class="w-full" name="risultati_pagina" v-model="user.storico_gg">
                        <option type="radio" name="risultati_pagina" id="risultati_pagina" value="0">0</option>
                        <option type="radio" name="risultati_pagina" id="risultati_pagina" value="30">30</option>
                        <option type="radio" name="risultati_pagina" id="risultati_pagina" value="60">60</option>
                        <option type="radio" name="risultati_pagina" id="risultati_pagina" value="90">90</option>
                    </select>
                </div>
            </div>
            <div class="flex flex-wrap">
                <div class="md:w-1/2 px-3 w-full my-4">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        {{ __('Password')}}
                    </label>
                    <div class="w-full flex inline">
                        <input
                            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            v-model="user.password" :type="showPassword ? 'text' : 'password'" placeholder="...">
                        <div @click="showPassword = !showPassword" class="cursor-pointer px-4">
                            {{showPassword ? 'hide' : 'show'}}
                        </div>
                    </div>
                </div>
                <div class="md:w-1/2 px-3 w-full my-4">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        {{ __('Ripeti Password')}}
                    </label>
                    <div class="w-full flex inline">
                        <input
                            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            v-model="repeat_password" :type="showRepeatPassword ? 'text' : 'password'"
                            placeholder="...">
                        <div @click="showRepeatPassword = !showRepeatPassword" class="cursor-pointer px-4">
                            {{showRepeatPassword ? 'hide' : 'show'}}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="user.role=='client' && contratti.length > 0">
                <div class="flex flex-wrap">
                    <div class="md:w-1/2 px-3 w-full my-4">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            {{ __('Filiale competenza')}}
                        </label>
                        <input
                            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            v-model="user.filcompetenza" maxlength="2" type="text" placeholder="..." required>
                    </div>
                    <div class="md:w-1/2 px-3 w-full my-4">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            {{ __('Codice per ricerca da riferimento mittente per destinatari (url:index2)')}}
                        </label>
                        <input
                            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            v-model="user.code" type="text" placeholder="...">
                    </div>
                </div>
                <div class="p-4">
                    <label class="uppercase text-l font-bold">
                        Contratti
                    </label>
                </div>
                <div class="w-full overflow-y-scroll px-3 h-36 p-2 border rounded border-black">
                    <div class="px-3 flex inline" v-for="(contratto, index) in contratti" :key="index">
                        <input type="checkbox" v-model="contratto.checked" :id="contratto.CMCON" />
                        <label class="px-2 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            :for="contratto.CMCON">
                            {{contratto.CMDES}}
                        </label>
                    </div>
                </div>
            </div>


            <div v-if="user.role == 'courier'">
                    <div class="p-4">
                        <label class="uppercase text-l font-bold">
                            Funzionalità corriere
                        </label>
                    </div>
                    <div class="flex flex-wrap border rounded border-black">
                        <div class="md:w-1/2 px-6 w-full my-4 flex inline align-center">
                            <input type="checkbox" v-model="user.datetime_referencing" id="datetime_referencing" />
                            <label class="px-2 block uppercase tracking-wide text-gray-700 text-xs font-bold" for="datetime_referencing">
                                {{ __('Data/Ora refertazione Read Only') }}
                            </label>
                        </div>
                        <div class="md:w-1/2 px-6 w-full my-4 flex inline align-center">
                            <input type="checkbox" v-model="user.shoot_hesitate" id="shoot_hesitate" />
                            <label class="px-2 block uppercase tracking-wide text-gray-700 text-xs font-bold" for="shoot_hesitate">
                                {{ __('Spara ed esita') }}
                            </label>
                        </div>
                    </div>
                </div>

            <div v-if="user.role=='client' && permessi.length > 0 && contratti.length > 0">
                <div class="p-4">
                    <label class="uppercase text-l font-bold">
                        Permessi
                    </label>
                </div>
                <div class="w-full overflow-y-scroll px-3 p-2">
                    <div class="w-full px-3 flex inline" v-for="(permesso, index) in permessi" :key="index">
                        <input type="checkbox" v-model="permesso.checked" :id="'permesso'+index" />
                        <label class="px-2 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            :for="'permesso'+index">
                            {{permesso.label}}
                        </label>
                    </div>
                </div>
            </div>
            <div v-if="user.role=='courier' && eventi.length > 0 && contratti.length == 0">
                <div class="p-4 flex flex-wrap justify-between">
                    <label class="uppercase text-l font-bold my-2">
                        Eventi
                    </label>
                    <input
                        class="appearance-none block md:w-1/3 w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text" @input="filterEventi($event)" :placeholder="__('filter eventi')" />
                </div>
                <div class="w-full overflow-y-scroll px-3 h-96 p-2 border rounded border-black">
                    <div v-for="(evento, index) in eventi" :key="index">
                        <div class="px-3 flex inline" v-if="evento.show">
                            <input type="checkbox" v-model="evento.checked" :id="'evento'+index" />
                            <label class="px-2 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                :for="'evento'+index">
                                {{evento.DESCRIZION}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap ">
                <div class="px-3 flex inline justify-between items-center w-full">
                    <div class="mt-4 text-center cursor-pointer bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded"
                        @click="clear">
                        {{__('annulla')}}
                    </div>
                    <button
                        class="ml-4 mt-4 focus:outline-none text-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded"
                        type="submit">
                        {{ newUser ? __('crea') : __('modifica')}}
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
export default {
    props: {
        user: Object,
        role: String,
        newUser: Boolean
    },
    data() {
        return {
            showPassword: false,
            showRepeatPassword: false,
            eventi: [],
            repeat_password: undefined,
            contratti: [],
            permessi: [
                /*{
                    label: 'Abilita Opzioni Avanzate di Connessione',
                    value: 'fastlink',
                    checked: false
                },*/
                {
                    label: 'Abilita Esportazione Excel e Pdf',
                    value: 'canexport',
                    checked: false
                },
                {
                    label: 'Visualizza Dettagli Trazionista',
                    value: 'viewtra',
                    checked: false
                },
                {
                    label: 'Visualizza Dettagli Corriere',
                    value: 'viewcorr',
                    checked: false
                },
                {
                    label: 'Visualizza Telefono Corriere',
                    value: 'viewtel',
                    checked: false
                },
                {
                    label: 'Visualizzazione POD',
                    value: 'canpdf',
                    checked: false
                },
                {
                    label: 'Abilita Visualizzazione LINK Al Portale DHL',
                    value: 'show_dhl_tracking_link',
                    checked: false
                }
            ]
        }
    },
    async created() {
        await this.storeLogTime(async () => {
            const results = [];
            this.user.password = this.user.pwd;
            this.repeat_password = this.user.pwd;
            if (this.user.role == 'client') {
                const filClient = this.user.filiale;
                const codeClient = parseInt(this.user.codice);
                let res = await this.handleCallback(() => this.axios.post(this.$store.getters.uri + '/contratti', {
                    filters: [
                        this.createFilter('CMFIL', filClient), this.createFilter('CMCOD', codeClient)
                    ]
                }));
                this.contratti = res.value || res;
                results.unshift(res);
                this.contratti.forEach(c => c.checked = this.user.contratti.find(cu => c.CMCON == cu.contratto && c.CMFIL == filClient && c.CMCOD == codeClient) == undefined);
            }
            if (this.user.eventi) {
                let body = { risultati_pagina: 200, filters: [], order_by: { fields: ['DESCRIZION'], ord: 'asc' } };
                let res = await this.handleCallback(() => this.axios.post(this.$store.getters.uri + '/eventi', body));
                results.unshift(res);
                this.eventi = res.data;
                while (res.next_page_url) {
                    let res = await await this.handleCallback(() => this.axios.post(res.next_page_url, body));
                    results.unshift(res);
                    res.data.forEach(e => this.eventi.push(e));
                }
                for (let e in this.eventi)
                    if (this.user.eventi.find(v => this.eventi[e].TAPRF == v.TBTEV && this.eventi[e].TAPRO == v.TBCEV))
                        this.eventi[e].checked = true;
                this.eventi.forEach(e => e.show = true);
            }
            for (let p in this.permessi)
                if (this.user[this.permessi[p].value] == 1)
                    this.permessi[p].checked = true;
            if (this.newUser) this.createUser();
            return results;
        });
    },
    methods: {
        filterEventi(event) {
            this.eventi.forEach(e => e.show = false);
            this.eventi.filter(e => e.DESCRIZION.toUpperCase().includes(event.target.value.toUpperCase())).forEach(e => e.show = true);
            for (let e in this.eventi)
                this.$set(this.eventi, e, Object.assign({}, this.eventi[e]));
        },
        createFilter(field, value) {
            return [{
                field: field,
                value: value,
                operator: '=',
                type: 'text'
            }];
        },
        async createUser() {
            this.permessi.forEach(p => this.user[p.value] = p.checked);
            this.user.eventi = this.eventi.filter(e => e.checked);
            this.user.contratti = this.contratti.filter(c => !c.checked);
            this.$emit('createUser', this.user, this.repeat_password);
        },
        clear() {
            this.permessi.forEach(p => p.checked = false);
            this.eventi.forEach(e => e.checked = false);
            this.$emit('annulla');
        }
    }
}
</script>
