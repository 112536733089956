<template>
    <div class="flex-1 min-w-0">
        <h2 class="mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight">
            {{__("Spedizioni")}}
        </h2>
        <div class="mx-auto sm:px-6">
            <div class="p-4 md:w-1/2 px-3 w-full my-4">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    {{ __('Risultati per pagina')}}
                </label>
                <div>
                    <select class="w-full" name="risultati_pagina" v-model="data.risultati_pagina" @change="search()">
                        <option type="radio" name="risultati_pagina" id="risultati_pagina" value="50">50</option>
                        <option type="radio" name="risultati_pagina" id="risultati_pagina" value="100">100</option>
                        <option type="radio" name="risultati_pagina" id="risultati_pagina" value="150">150</option>
                    </select>
                </div>
            </div>


            <form class="p-10 text-center m-4 border border-gray-700" @submit.prevent="referta">
                <div class="flex flex-wrap">
                    <div class="md:w-1/2 px-3 w-full my-4">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            {{ __('Data')}}
                        </label>
                        <input
                            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            v-model="daRefertare.DATA" type="date">
                    </div>
                    <div class="md:w-1/2 px-3 w-full my-4">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            {{ __('Ora')}}
                        </label>
                        <input
                            class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            v-model="daRefertare.ORA" type="time">
                    </div>
                </div>
                <div class="flex flex-wrap">
                    <div class="md:w-1/2 px-3 w-full my-4">
                        <AutoComplete :title="__('Esito Evento')" :url="$store.getters.uri+'/events-params'"
                            :text="auto_complete.EVENTO.text" :selectedOption="auto_complete.EVENTO.selectedOption"
                            :mapOptionTitle="(option) => option.length > 0 ? option[0].value : ''" @chosen="(c) => {
                                auto_complete.EVENTO.text = c.text;
                                auto_complete.EVENTO.selectedOption = c.selectedOption;
                                if(auto_complete.EVENTO.selectedOption)
                                    daRefertare.EVENTO = auto_complete.EVENTO.selectedOption;
                                else
                                    daRefertare.EVENTO = null;
                            }"
                            :searchOptions="(url, word) => searchBase(url, {params: ['DESCRIZION', 'TAPRF', 'TAPRO'], word: word})">
                        </AutoComplete>
                    </div>
                    <div class="md:w-1/2 px-3 w-full my-4">
                        <button :disabled="!anyCodice"
                            class="mt-4 focus:outline-none text-center bg-red-300 text-white font-bold py-2 px-4 bg-red-500 hover:bg-red-700 border rounded"
                            type="submit">
                            {{ __('Referta')}}
                        </button>
                    </div>
                </div>
            </form>
            <Table :items="[
                {label: __('Codice'), value: 'id_spedizione', type: 'combination', 
                    map: (obj) => formatSpedizione(obj),
                    subItems: ['T05_BOANB', 'T05_BOFBO', 'T05_BOKEY']
                },
                {label: __('Mittente'), value: 'id_spedizione', type: 'combination', 
                    map: (obj) => obj['T05_BOMIT']+'</br>'+obj['T05_BOMIN']+' - '+obj['T05_BOMLO']+'('+obj['T05_BOMPR']+')',
                    subItems: ['T05_BOMCO', 'T05_BOMIT', 'T05_BOMIN', 'T05_BOMLO', 'T05_BOMPR']
                },
                {label: __('Destinatario'), value: 'id_spedizione', type: 'combination', 
                    map: (obj) => obj['T05_BODIT']+'</br>'+obj['T05_BODIN']+' - '+obj['T05_BODLO']+'('+obj['T05_BODPR']+')',
                    subItems: ['T05_BODIT', 'T05_BODIN', 'T05_BODLO', 'T05_BODPR']
                },
                {label: __('Data'), value: 'T05_BODTB', type: 'text'},
                {label: __('Referta'), value: 'referta', type: 'buttons', onclick: [clickReferta], denyOrd: true,
                    values: (e) => daRefertare.CODICI.includes(formatSpedizione(e)) ? ['-'] : ['+']}
            ]" :result="result" name="Spedizioni" @searchPage="searchPage" @orderBy="orderBy"></Table>
        </div>
    </div>
</template>
<script>
import Table from './utility/Table.vue';
import AutoComplete from './filters/AutoComplete.vue';

export default {
    components: {
        AutoComplete,
        Table
    },
    data() {
        return {
            minDate: undefined,
            maxDate: undefined,
            result: undefined,
            data: {
                risultati_pagina: 50,
                filters: []
            },
            lastSearch: '',
            order_by: {
                fields: ['T05_BODTB'],
                ord: 'desc'
            },
            daRefertare: {
                DATA: undefined,
                ORA: undefined,
                CODICI: [],
                EVENTO: ''
            },
            auto_complete: {
                EVENTO: { text: '' }
            }
        }
    },
    computed: {
        anyCodice() {
            return this.daRefertare.CODICI.length > 0;
        }
    },
    async created() {
        this.updateDateTime();
        this.lastSearch = this.$store.getters.uri + '/spedizioni';
        this.search();
        const res = await this.searchBase(this.$store.getters.uri + '/events-params', { word: '', params: ['DESCRIZION', 'TAPRF', 'TAPRO'] });
        const evento = res.data[0][0];
        this.auto_complete.EVENTO.text = evento.value;
        this.auto_complete.EVENTO.selectedOption = [evento];
        this.daRefertare.EVENTO = res.data[0];
    },
    methods: {
        updateDateTime() {
            const now = new Date().toLocaleString().split(', ');
            //const date = now[0].split('/');
            //this.daRefertare.DATA = date[2]+'-'+date[1]+'-'+date[0];
            this.daRefertare.ORA = now[1].substring(0, 5);
        },
        formatSpedizione(obj) {
            return (obj['T05_BOANB'] + '').padStart(2, '0') + (obj['T05_BOFBO'] + '').padStart(2, '0') + (obj['T05_BOKEY'] + '').padStart(7, '0');
        },
        clickReferta(obj) {
            const spedizione = this.formatSpedizione(obj);
            if (!this.daRefertare.CODICI.includes(spedizione))
                this.daRefertare.CODICI.push(spedizione);
            else
                this.daRefertare.CODICI = this.daRefertare.CODICI.filter(c => c != spedizione);
        },
        search() {
            this.searchPage(this.lastSearch);
        },
        async searchPage(url) {
            if (url) {
                this.lastSearch = url;
                this.data.order_by = this.order_by;
                let res = await this.handleCallback(() => this.axios.post(url, this.data));
                this.$set(this, 'result', res);
            }
        },
        orderBy(ord) {
            this.order_by = ord;
            this.searchPage(this.lastSearch);
        },
        async searchBase(url, data = null) {
            let res = null;
            await this.storeLogTime(async () => {
                res = await this.handleCallback(() => this.axios.post(url, data), false, true, false);
                return res;
            });
            return res;
        },
        async referta() {
            await this.storeLogTime(async () => {
                let res = null;
                if (this.daRefertare.EVENTO && this.daRefertare.DATA && this.daRefertare.ORA) {
                    res = await this.handleCallback(() => this.axios.post(this.$store.getters.uri + '/referta', { referto: this.daRefertare }), true);
                    this.daRefertare.CODICI.splice(0);
                } else
                    this.$emit('notify', false, this.__('Elementi mancanti'));
            });
        },
    }
}
</script>