<template>
  <div>
    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
      {{ title }}
    </label>
    <div class="w-full flex inline">
      <input autocomplete="off" :ref="reff" :class="{'bg-red-400 focus:bg-red-400' : !text && resend}"
        class="text-xs appearance-none block bg-gray-200 text-gray-700 border border-gray-200 w-full rounded px-4 leading-tight focus:outline-none focus:border-gray-500"
        v-model="text" type="text" placeholder="..." @input="listOptions()" @click="inputText()">
    </div>
    <div v-show="showOptions" 
      class="origin-top-right absolute mt-2 overflow-scroll max-h-40 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu" aria-orientation="vertical">
      <ul class="py-1 overflow-y-scroll">
        <li v-for="(option, index) in filteredOptions" :key="index" @click="selectOption(option)" class="px-3 py-2 cursor-pointer hover:bg-gray-200">
          {{ mapOptionTitle(option) }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    reff: String,
    mapOptionTitle: Function,
    title: String,
    options: Array,
    resend: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    selectedOption: Object
  },
  data() {
    return {
      showOptions: false
    }
  },
  computed: {
      filteredOptions() {
        const res = this.options.filter(o => this.mapOptionTitle(o).includes(this.text != null ? this.text.toUpperCase() : ''));
        res.sort((x, y) => x.length - y.length);
        return res;
      }
  },
  methods: {
    emitValues(text, option = undefined) {
      this.$emit('chosen', {
        text: text,
        selectedOption: option
      });
    },
    inputText() {
      this.showOptions = true;
    },
    listOptions() {
      this.emitValues(this.text);
      this.inputText();
    },
    selectOption(option) {
      this.showOptions = false;
      this.emitValues(this.mapOptionTitle(option), option);
    },
    close(e) {
      if (!this.$el.contains(e.target) && this.options.length > 0) {
        this.showOptions = false;
      }
    },
    cancel() {
      this.showOptions = false;
      this.emitValues('', undefined);
    }
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  }
}
</script>