<template>
    <div v-if="extended" class="flex">
        <router-link :to="{name: 'dashboard'}" class="hover:bg-gray-200 rounded-md px-2 m-1 flex-shrink-0 flex items-center grid">
            <img src="/img/TardiniLogo.svg" class="block w-auto" />
            <div class="uppercase tracking-wide font-bold text-xs text-gray-700">
                <p>WEB TRACKING</p>
            </div>
        </router-link>
    </div>
    <div v-else class="block px-4 pt-2 pb-3 space-y-1">
        <router-link :to="{name: 'dashboard'}">
            {{ __('Dashboard') }}
        </router-link>
    </div>
</template>
<script>
    export default {
        props: {
            extended: Boolean
        }
    }
</script>