<template>
    <div class="flex-1 min-w-0">
        <h2 class="mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight">
            {{__("Visualizza Prenotazioni")}}
        </h2>
        <div class="py-12">
            <div class="mx-auto sm:px-6">
                <GeneralFilter :in_rows="
                [
                    [
                        {
                            type: 'select_option',
                            src: 'local',
                            mapOptionTitle: (option) => option[0].label,
                            header: __('Tutti'),
                            params: [
                            [{
                                operator: '<',
                                field: 'TXDAT',
                                type: 'text',
                                value: new Date(),
                                label: __('Non ancora ritirati')
                            }],
                            [{
                                operator: '>',
                                field: 'TXDAT',
                                type: 'text',
                                value: new Date(),
                                label: __('Ritirati')
                            }]
                            ]
                        }
                    ],
                    [
                        {
                            type: 'date',
                            title: __('Data inizio'),
                            filter: {
                                operator: '>=',
                                field: 'TXDAT',
                                type: 'data'
                            }
                        },
                        {
                            type: 'date',
                            title: __('Data fine'),
                            filter: {
                                operator: '<=',
                                field: 'TXDAT',
                                type: 'data'
                            }
                        }
                    ]
                ]" :url="$store.getters.uri+'/params-ritiri'" @search="search"></GeneralFilter>
                <Table :items="[
                    {label: __('Località ritiro'), value: 'localita_ritiro', type: 'combination',
                        map: (obj) => {
                            let res = '';
                            if(obj['TXMLO']) res+='<b>Città</b>: '+obj['TXMLO']+'</br>';
                            if(obj['TXMIN']) res+='<b>Indirizzo</b>: '+obj['TXMIN']+'</br>';
                            if(obj['TXMDE']) res+='<b>Presso</b>: '+obj['TXMDE']+'</br>';
                            if(obj['TXMPV']) res+='<b>Provincia</b>: '+obj['TXMPV'];
                            return res;
                        },
                        subItems: ['TXMLO', 'TXMIN', 'TXMDE'],
                    },
                    {label: __('Destinatario'), value: 'destinatario', type: 'combination',map: (obj) => {
                            let res = '';
                            if(obj['TXDLO']) res+='<b>Città</b>: '+obj['TXDLO']+'</br>';
                            if(obj['TXDIN']) res+='<b>Indirizzo</b>: '+obj['TXDIN']+'</br>';
                            if(obj['TXDDE']) res+='<b>Presso</b>: '+obj['TXDDE']+'</br>';
                            if(obj['TXDPV']) res+='<b>Provincia</b>: '+obj['TXDPV'];
                            return res;
                        },
                        subItems: ['TXDLO', 'TXDIN', 'TXDDE'],
                    },
                    {label: __('Data Ritiro'), value: 'TXDAT', type: 'text'},
                    /*{label: __('Data Com'), value: 'dataComunicazione', type: 'combination',
                        map: (obj) => obj['dataComunicazione']+'</br>'+obj['oraComunicazione'],
                        subItems: ['dataComunicazione', 'oraComunicazione'],
                    },*/
                    {label: __('Num Banc.'), value: 'numero_bancali', type: 'combination', map: (obj) => {
                        let n = parseInt(obj['TXFLR']);
                        return n==0 ? ' ' : n;
                    }},
                    {label: __('Num. Coll.'), value: 'TXCOL', type: 'text'},
                    {label: __('Peso'), value: 'TXPES', type: 'text'},
                    {label: __('Volume'), value: 'TXVOL', type: 'text'},
                    {label: __('Stato'), value: 'stato', type: 'combination', denyOrd: true, map: (obj) => obj['received'] ? '<b style=\'color: green\'>RICEVUTO</b>' : '<b style=\'color: orange\'>IN ATTESA</b>'}
                ]" :result="result" name="Ritiri" @searchPage="searchPage" @orderBy="orderBy"></Table>
            </div>
        </div>
    </div>
</template>
<script>
import GeneralFilter from './filters/GeneralFilter.vue';
import Table from './utility/Table.vue';

export default {
    components: {
        GeneralFilter,
        Table
    },
    data() {
        return {
            result: undefined,
            data: undefined,
            lastSearch: undefined,
            order_by: {
                fields: ['TXDAT'],
                ord: 'desc'
            }
        }
    },
    methods: {
        search(data) {
            this.data = data;
            this.lastSearch = this.$store.getters.uri + '/ritiri';
            this.searchPage(this.lastSearch);
        },
        async searchPage(url) {
            await this.storeLogTime(async () => {
                let res = null;
                if (url) {
                    this.lastSearch = url;
                    this.data.order_by = this.order_by;
                    res = await this.handleCallback(() => this.axios.post(url, this.data));
                    this.$set(this, 'result', res);
                }
                return res;
            });
        },
        orderBy(ord) {
            this.order_by = ord;
            this.searchPage(this.lastSearch);
        }
    }
}
</script>