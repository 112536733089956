var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-1 min-w-0"},[_c('h2',{staticClass:"mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight"},[_vm._v("\n        "+_vm._s(_vm.__("Visualizza Prenotazioni"))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"py-12"},[_c('div',{staticClass:"mx-auto sm:px-6"},[_c('GeneralFilter',{attrs:{"in_rows":[
                [
                    {
                        type: 'select_option',
                        src: 'local',
                        mapOptionTitle: function (option) { return option[0].label; },
                        header: _vm.__('Tutti'),
                        params: [
                        [{
                            operator: '<',
                            field: 'TXDAT',
                            type: 'text',
                            value: new Date(),
                            label: _vm.__('Non ancora ritirati')
                        }],
                        [{
                            operator: '>',
                            field: 'TXDAT',
                            type: 'text',
                            value: new Date(),
                            label: _vm.__('Ritirati')
                        }]
                        ]
                    }
                ],
                [
                    {
                        type: 'date',
                        title: _vm.__('Data inizio'),
                        filter: {
                            operator: '>=',
                            field: 'TXDAT',
                            type: 'data'
                        }
                    },
                    {
                        type: 'date',
                        title: _vm.__('Data fine'),
                        filter: {
                            operator: '<=',
                            field: 'TXDAT',
                            type: 'data'
                        }
                    }
                ]
            ],"url":_vm.$store.getters.uri+'/params-ritiri'},on:{"search":_vm.search}}),_vm._v(" "),_c('Table',{attrs:{"items":[
                {label: _vm.__('Località ritiro'), value: 'localita_ritiro', type: 'combination',
                    map: function (obj) {
                        var res = '';
                        if(obj['TXMLO']) { res+='<b>Città</b>: '+obj['TXMLO']+'</br>'; }
                        if(obj['TXMIN']) { res+='<b>Indirizzo</b>: '+obj['TXMIN']+'</br>'; }
                        if(obj['TXMDE']) { res+='<b>Presso</b>: '+obj['TXMDE']+'</br>'; }
                        if(obj['TXMPV']) { res+='<b>Provincia</b>: '+obj['TXMPV']; }
                        return res;
                    },
                    subItems: ['TXMLO', 'TXMIN', 'TXMDE'],
                },
                {label: _vm.__('Destinatario'), value: 'destinatario', type: 'combination',map: function (obj) {
                        var res = '';
                        if(obj['TXDLO']) { res+='<b>Città</b>: '+obj['TXDLO']+'</br>'; }
                        if(obj['TXDIN']) { res+='<b>Indirizzo</b>: '+obj['TXDIN']+'</br>'; }
                        if(obj['TXDDE']) { res+='<b>Presso</b>: '+obj['TXDDE']+'</br>'; }
                        if(obj['TXDPV']) { res+='<b>Provincia</b>: '+obj['TXDPV']; }
                        return res;
                    },
                    subItems: ['TXDLO', 'TXDIN', 'TXDDE'],
                },
                {label: _vm.__('Data Ritiro'), value: 'TXDAT', type: 'text'},
                /*{label: __('Data Com'), value: 'dataComunicazione', type: 'combination',
                    map: (obj) => obj['dataComunicazione']+'</br>'+obj['oraComunicazione'],
                    subItems: ['dataComunicazione', 'oraComunicazione'],
                },*/
                {label: _vm.__('Num Banc.'), value: 'numero_bancali', type: 'combination', map: function (obj) {
                    var n = parseInt(obj['TXFLR']);
                    return n==0 ? ' ' : n;
                }},
                {label: _vm.__('Num. Coll.'), value: 'TXCOL', type: 'text'},
                {label: _vm.__('Peso'), value: 'TXPES', type: 'text'},
                {label: _vm.__('Volume'), value: 'TXVOL', type: 'text'},
                {label: _vm.__('Stato'), value: 'stato', type: 'combination', denyOrd: true, map: function (obj) { return obj['received'] ? '<b style=\'color: green\'>RICEVUTO</b>' : '<b style=\'color: orange\'>IN ATTESA</b>'; }}
            ],"result":_vm.result,"name":"Ritiri"},on:{"searchPage":_vm.searchPage,"orderBy":_vm.orderBy}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }