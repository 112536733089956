<script>
export default {
  methods: {
    exportExcel(data, name) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a')
        link.href = url;
        let date = new Date();
        let suffix = "_" + date.toLocaleDateString() + "_" + date.toLocaleTimeString() + ".xlsx";
        link.setAttribute('download', name + suffix);
        document.body.appendChild(link);
        link.click();
    }
  }
}
</script>