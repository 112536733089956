<template>
  <nav class="bg-white border-b border-gray-100 shadow">
    <div class="mx-auto px-4 sm:px-6">
      <div class="flex justify-between h-16">
        <HomeMenu @setLoading="setLoading" @notify="notify" :extended="true"></HomeMenu>
        <NavigationLink :extended="true"></NavigationLink>
        <UserMenu @setLoading="setLoading" @notify="notify" :extended="true"></UserMenu>
        <HamburgerMenu :openMenu="openMenu" @menuAction="menuAction"></HamburgerMenu>
      </div>
    </div>

    <!-- Responsive Navigation Menu -->
    <div :class="{'block': openMenu, 'hidden': !openMenu}" class="sm:hidden" @click="openMenu = false">
      <HomeMenu :extended="false"></HomeMenu>
      <div class="pt-4 pb-1 border-t border-gray-200">
        <div class="mt-3 space-y-1">
          <NavigationLink @setLoading="setLoading" @notify="notify" :extended="false"></NavigationLink>
        </div>
      </div>
      <div class="pt-4 pb-1 border-t border-gray-200">
        <div class="mt-3 space-y-1">
          <UserMenu :extended="false" @setLoading="setLoading" @notify="notify"></UserMenu>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
  import HomeMenu from './HomeMenu';
  import NavigationLink from './NavigationLink';
  import UserMenu from './UserMenu';
  import HamburgerMenu from './HamburgerMenu';

  export default {
    components: {
      HomeMenu,
      NavigationLink,
      UserMenu,
      HamburgerMenu
    },
    data() {
      return {
        openMenu: false,
      }
    },
    methods: {
      menuAction(flag) {
        this.openMenu = flag;
      },
      close(e) {
        if (!this.$el.contains(e.target)) {
          this.menuAction(false);
        }
      },
      setLoading(flag) {
        this.$emit('setLoading', flag);
      },
      notify(status, message) {
        this.$emit('notify', status, message);
      }
    },
    mounted() {
      document.addEventListener('click', this.close);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.close);
      }
  }
</script>