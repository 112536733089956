<template>
  <div :class="[loading ? 'bg-yellow-400' : '']">
    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
      {{ title }}
      <span v-if="loading" :class="[loading ? 'text-red-800' : '']"> | <strong>Caricamento dati
          Attendere....</strong></span>
      <span v-if="selectedOption.length > 0"> | {{ mapOptionTitle(selectedOption) }}</span>
    </label>
    <div class="w-full flex inline">
      <input autocomplete="off" :maxlength="maxlength" :ref="reff" :readonly="readonly"
        class="text-xs appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:border-gray-500"
        :class="[(resend && (!text || selectedOption.length == 0)) ? 'bg-red-400 focus:bg-red-400' : '', selectedOption.length > 0 ? 'bg-green-200 w-11/12' : 'w-full', loading ? 'bg-yellow-600' : '']"
        v-model="text" type="text" placeholder="..." @click="inputText()" @input="debounceInput"
        @blur="checkAutocompleteValueSelected">
      <svg v-if="selectedOption.length > 0 && !readonly" @click="cancel()" xmlns="http://www.w3.org/2000/svg"
        class="m-auto cursor-pointer h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z" />
      </svg>
    </div>
    <div v-show="(showOptions || showOptionsOut) && !readonly" v-on:focus="focusOnList" v-on:blur="bluOnList"
      class="origin-top-right absolute mt-2 overflow-scroll max-h-40 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu" aria-orientation="vertical">
      <ul class="py-1 overflow-y-scroll">
        <li v-for="(option, index) in options" :key="index" @click="selectOption(option)"
          class="px-3 py-2 cursor-pointer hover:bg-gray-200">
          {{ mapOptionTitle(option) }}
        </li>
        <li v-if="paginator && paginator.to > 0 && paginator.to % paginator.per_page === 0" @click="loadOther"
          class="px-3 py-2 cursor-pointer hover:bg-gray-200">
          ...
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';

export default {

  props: {
    _focusOnList: {
      type: Boolean,
      default: false
    },
    reff: String,
    autoSelectOneOption: {
      type: Boolean,
      default: false
    },
    resend: {
      type: Boolean,
      default: false
    },
    showOptionsOut: {
      type: Boolean,
      default: false
    },
    mapOptionTitle: Function,
    searchOptions: Function,
    title: String,
    url: String,
    maxlength: {
      type: Number,
      default: 30
    },
    text: {
      type: String,
      default: ''
    },
    selectedOption: {
      type: Array,
      default() {
        return []
      }
    },
    readonly: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: [],
      paginator: undefined,
      loading: false,
      showOptions: false,
      cancelled: false,
    }
  },
  methods: {
    emitValues(text, option = undefined) {
      this.$emit('chosen', {
        text: text,
        selectedOption: option
      });
    },
    async inputText() {
      if (!this.readonly) {
        //Non avvia la ricerca se ho già selezionato un elemento, o elimino o modifico i caratteri
        if (this.selectedOption.length > 0) {
          return;
        }
        this.loading = true;
        this.showOptions = true;
        await this.search();
        if (!this.cancelled && this.autoSelectOneOption && this.options.length == 1)
          this.selectOption(this.options[0]);
      }
    },
    checkAutocompleteValueSelected: _.debounce(function () {
      if (this.selectedOption.length <= 0) {
        this.cancel();
      }
    }, 200),
    debounceInput: _.debounce(function () {
      this.listOptions()
    }, 400),
    listOptions() {
      this.emitValues(this.text);
      this.inputText();
      //TODO Capire come mai era stata inserita la riga this.cancelled = true; forse in qualche maschera non funziona correttamente
      //this.cancelled = true;
    },
    async search() {
      this.$set(this, 'paginator', await this.searchOptions(this.url, this.text));
      this.options = this.paginator ? this.paginator.data : [];
      this.options.sort((x, y) => x[0].value.length - y[0].value.length);
      if(this.autocomplete && this.options.length == 1) {
        this.selectOption(this.options[0]);
      }
      this.loading = false;
    },
    async loadOther() {
      this.loading = true;
      this.$set(this, 'paginator', await this.searchOptions(this.paginator.next_page_url, this.text));
      this.paginator.data.forEach(d => this.options.push(d));
      this.loading = false;
    },
    selectOption(option) {
      this.showOptions = false;
      this.emitValues(this.mapOptionTitle(option), option);
    },
    close(e) {
      if (!this.$el.contains(e.target) && this.options.length > 0) {
        this.showOptions = false;
      }
    },
    async cancel() {
      await this.$emit('cancelCallback');
      this.cancelled = true;
      this.showOptions = false;
      this.emitValues('', undefined);
    },
    focusOnList() {
      this._focusOnList = true;
    },
    bluOnList() {
      this._focusOnList = false;
    }
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  }
}
</script>
