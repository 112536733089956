<template>
    <div class="flex-1 min-w-0">
        <h2 class="mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight">
            {{__("Visualizza PESATE")}}
        </h2>
        <div class="py-12">
            <div class="mx-auto sm:px-6">
                <GeneralFilter :in_rows="
                [
                    [
                        {
                            type: 'date',
                            title: __('Data inizio'),
                            filter: {
                                operator: '>',
                                field: 'created_at',
                                type: 'text',
                                value: fromDate
                            },
                            text: fromDate
                        },
                        {
                            type: 'date',
                            title: __('Data fine'),
                            filter: {
                                operator: '<=   ',
                                field: 'created_at',
                                type: 'text',
                                value: toDate
                            },
                            text: toDate
                        }
                    ]
                ]" :all="true" :url="$store.getters.uri+'/params-storicopesate'" @search="search"></GeneralFilter>
                <Table :items="[
                    {label: __('Data'), value: 'created_at', type: 'date', cssclass: ''},
                    {label: __('ID Spedizione'), value: 'package', type: 'text', cssclass: ''},
                    {label: __('Peso'), value: 'weight', type: 'text', cssclass: 'text-right'},
                    {label: __('Terminale'), value: 'devicenumber', type: 'text', cssclass: ''},
                    {label: __('Baia'), value: 'bay', type: 'text', cssclass: ''},
                    {label: __('Riferimento Baia'), value: 'qrcode_extended', type: 'text', cssclass: ''}
                ]" :result="result" name="Visualizza_PESATE" @searchPage="searchPage" @orderBy="orderBy" :exportTable="true">
                </Table>
            </div>
        </div>
    </div>
</template>
<script>
import GeneralFilter from './filters/GeneralFilter.vue';
import Table from './utility/Table.vue';

export default {
    components: {
        GeneralFilter,
        Table
    },
    data() {
        return {
            result: undefined,
            data: undefined,
            lastSearch: undefined,
            order_by: {
                fields: ['created_at'],
                ord: 'desc'
            },
            fromDate: undefined,
            toDate: undefined
        }
    },
    created() {

        let now = new Date();
        let minDate = new Date(now.getFullYear(), now.getMonth() - 1, 2);
        let maxDate = now;

        this.fromDate = minDate.toISOString().split('T')[0];
        this.toDate = maxDate.toISOString().split('T')[0];
    },
    methods: {
        search(data) {
            this.data = data;
            this.lastSearch = this.$store.getters.uri + '/storicopesate';
            this.searchPage(this.lastSearch);
        },
        async searchPage(url) {
            await this.storeLogTime(async () => {
                let res = null;
                if (url) {
                    this.lastSearch = url;
                    this.data.order_by = this.order_by;
                    res = await this.handleCallback(() => this.axios.post(url, this.data));
                    this.$set(this, 'result', res);
                }
                return res;
            });
        },
        orderBy(ord) {
            this.order_by = ord;
            this.searchPage(this.lastSearch);
        }
    }
}
</script>
