<template>
    <div class="flex-1 min-w-0">
        <div>
            <div class="mx-auto sm:px-6 my-4">
                <div class="bg-white overflow-hidden shadow-xl sm:rounded-lg">
                    <form class="p-2" @submit.prevent="creaRitiro">
                        <div class="flex flex-wrap">
                            <div class="md:w-1/2 px-3 my-2 w-full">
                                <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                                    {{__("Prenotazione Ritiro")}}
                                </h2>
                            </div>
                            <div class="md:w-1/2 px-3 my-2 w-full">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold" required
                                    for="data">
                                    {{ __('Data')}}
                                </label>
                                <input ref="TXDAT" :class="{'bg-red-400 focus:bg-red-400' : !data.TXDAT && resend}"
                                    class="text-xs appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="data" v-model="data.TXDAT" type="date" :min="minDay">
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                {{ __('Località ritiro')}}
                            </label>
                        </div>
                        <div class="bg-transparent border p-4 border-gray-500 rounded">
                            <RagioneSocialeLocalitaSelect ref="ragSocMitt" :auto_complete="mitt_auto_complete" :data="{
                                DESCRIZIONE: data.TXMDE,
                                OFCAP: data.TXMCA,
                                INDIRIZZO: data.TXMIN,
                                OFLOC: data.TXMLO,
                                OFCPD: data.TXMPV
                            }" tipor="a" :refs="{
                                DESCRIZIONE: 'TXMDE',
                                INDIRIZZO: 'TXMIN',
                                OFLOC: 'TXMLO',
                                OFCPD: 'TXMPV',
                                OFCAP: 'TXMCA',
                            }" :resend="resend" @chosen="(d) => {
                                data.TXMDE = d.DESCRIZIONE;
                                data.TXMCA = d.OFCAP;
                                data.TXMIN = d.INDIRIZZO;
                                data.TXMLO = d.OFLOC;
                                data.TXMPV = d.OFCPD;
                            }" @notify="(status, mex) => $emit('notify', status, mex)">
                            </RagioneSocialeLocalitaSelect>
                        </div>
                        <div class="flex flex-wrap mt-2">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold m-2">
                                {{ __('Destinatario')}}
                            </label>
                        </div>

                        <div class="bg-transparent border p-4 mb-4 border-gray-500 rounded"
                            v-for="(dest, index) in destinatari" :key="index">
                            <div class="w-full grid">
                                <div class="w-full flex inline justify-between">
                                    N.{{index+1}}
                                    <svg v-if="index>0" @click="removeDestinatario(index)"
                                        class="cursor-pointer h-6 w-6" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                                <RagioneSocialeLocalitaSelect :class="[index > 0 ? 'w-11/12' : 'w-full']"
                                    :ref="'ragSocDest_'+index" :auto_complete="dest.auto_complete" :data="{
                                        DESCRIZIONE: dest.TXDDE,
                                        OFCAP: dest.TXDCA,
                                        INDIRIZZO: dest.TXDIN,
                                        OFLOC: dest.TXDLO,
                                        OFCPD: dest.TXDPV
                                    }" :refs="{
                                        DESCRIZIONE: 'TXDDE_'+index,
                                        INDIRIZZO: 'TXDIN_'+index,
                                        OFLOC: 'TXDLO_'+index,
                                        OFCPD: 'TXDPV_'+index,
                                        OFCAP: 'TXMCA_'+index,
                                    }" :resend="false"
                                          :indexel="index+1"
                                        tipor="b" @chosen="(d) => {
                                        dest.TXDDE = d.DESCRIZIONE;
                                        dest.TXDCA = d.OFCAP;
                                        dest.TXDIN = d.INDIRIZZO;
                                        dest.TXDLO = d.OFLOC;
                                        dest.TXDPV = d.OFCPD;
                                    }" @notify="(status, mex) => $emit('notify', status, mex)">
                                </RagioneSocialeLocalitaSelect>
                            </div>
                            <div class="md:px-10">
                                <div class="flex flex-wrap">
                                    <label class="block text-gray-700 text-l font-bold">
                                        {{ __('DETTAGLI RITIRO (almeno un campo obbligatorio)')}}
                                    </label>
                                </div>
                                <div class="flex flex-wrap"
                                    :class="{'bg-red-400 border rounded': resend && !(dest.TXCOL || dest.TXPES || dest.TXVOL || dest.TXFLR)}">
                                    <div class="md:w-1/4 px-3 w-full my-2">
                                        <label
                                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                            for="dr_n_bancali">
                                            {{ __('N. Bancali')}}
                                        </label>
                                        <input
                                            class="text-xs appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            id="dr_n_bancali" v-model="dest.TXFLR" type="number" min="0"
                                            placeholder="...">
                                    </div>
                                    <div class="md:w-1/4 px-3 w-full my-2">
                                        <label
                                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                            for="dr_n_colli">
                                            {{ __('N. Colli')}}
                                        </label>
                                        <input
                                            class="text-xs text-xs appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            id="dr_n_colli" v-model="dest.TXCOL" type="number" min="0"
                                            placeholder="...">
                                    </div>
                                    <div class="md:w-1/4 px-3 w-full my-2">
                                        <label
                                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                            for="dr_kg">
                                            {{ __('Peso')}}
                                        </label>
                                        <input
                                            class="text-xs appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            id="dr_kg" v-model="dest.TXPES" type="number" min="0" step="0.01"
                                            placeholder="...">
                                    </div>
                                    <div class="md:w-1/4 px-3 w-full my-2">
                                        <label
                                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                            for="dr_mc">
                                            {{ __('Volume')}}
                                        </label>
                                        <input
                                            class="text-xs appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            id="dr_mc" v-model="dest.TXVOL" type="number" min="0" step="0.01"
                                            placeholder="...">
                                    </div>
                                </div>
                                <div class="flex flex-wrap">
                                    <div class="px-3 w-full my-2">
                                        <label
                                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                            for="note">
                                            {{ __('Note')}}
                                        </label>
                                        <textarea maxlength="100"
                                            class="text-xs appearance-none block w-full my-2 bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            id="note" v-model="dest.TXINO" type="number" placeholder="..."></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex inline justify-between items-center w-full my-2">
                            <div class="mt-2 cursor-pointer focus:outline-none text-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
                                @click="addDestinatario()">
                                {{__('aggiungi destinatario')}}
                            </div>
                            <button
                                class="mt-2 focus:outline-none text-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded"
                                type="submit">
                                {{ __('conferma prenotazione')}}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import RagioneSocialeLocalitaSelect from './utility/RagioneSocialeLocalitaSelect.vue';

export default {
    components: {
        RagioneSocialeLocalitaSelect
    },
    data() {
        return {
            minDay: new Date().toISOString().split('T')[0],
            destinatari: [],
            data: {
                TXTBO: ' ',
                TXDAT: null,
                TXSSE: null,
                TXMFI: null,
                TXMCD: null,
                TXMCN: null,
                TXMDE: null,
                TXMCA: null,
                TXMIN: null,
                TXMLO: null,
                TXMPV: null
            },
            mitt_auto_complete: {},
            campi_obbligatori: [],
            resend: false
        }
    },
    created() {
        this.addDestinatario();
        this.mitt_auto_complete = this.initAutoComplete();
        this.campi_obbligatori = [
            "TXDAT",
            "TXMDE",
            "TXMIN",
            "TXMLO",
            "TXMPV",
        ];
    },
    methods: {
        removeDestinatario(i) {
            this.$delete(this.destinatari, i);
        },
        addDestinatario() {
            let dest = {
                TXDFI: null,
                TXDCD: null,
                TXDCN: null,
                TXDDE: null,
                TXDCA: null,
                TXDIN: null,
                TXDLO: null,
                TXDPV: null,
                TXCOL: null,
                TXPES: null,
                TXVOL: null,
                TXINO: null,
                TXFLR: null,
                auto_complete: this.initAutoComplete()
            };
            this.destinatari.push(dest);
        },
        focusDeep(ref, text) {
            for (const r of Object.entries(ref)) {
                if (r[1].focus) {
                    if (r[0] == text) {
                        r[1].focus();
                        break;
                    }
                } else if (r[1] && r[1].$refs) {
                    this.focusDeep(r[1].$refs, text);
                } else if (r[1] && r[1][0].$refs) {
                    this.focusDeep(r[1][0].$refs, text);
                }
            }
        },
        async creaRitiro() {
            await this.storeLogTime(async () => {
                let res;
                if (this.checkUncompleted()) {
                    this.data.TXMFI = this.$store.state.user.filiale;
                    this.data.TXMCD = this.$store.state.user.codice;
                    this.data.TXMCN = 0;
                    this.data.TXDAT = this.data.TXDAT.replace(/\D/g, '');
                    const hours = new Date().getHours();
                    this.data.TXSSE = hours >= 0 && hours < 13 ? 1 : 2;
                    let ritiri = [];


                    this.destinatari.forEach(d => {
                        if (!d.TXDDE || !d.TXDDE.length || d.TXDDE.length == 0)
                            d.TXDDE = '';
                        if (!d.TXDIN || !d.TXDIN.length || d.TXDIN.length == 0)
                            d.TXDIN = '';
                        ritiri.push(Object.assign({}, d, this.data));
                    });
                    ritiri = ritiri.map(r => {
                        r.auto_complete = undefined;
                        return r;
                    });
                    res = await this.handleCallback(() => this.axios.post(this.$store.getters.uri + '/insert-ritiri', { ritiri: ritiri }), true);
                    if(res) {
                        this.clean();
                    } else {
                        this.data.TXDAT = '';
                        this.resend = true;
                    }
                } else {
                    this.$emit('notify', false, this.__('Inserisci tutti i campi'));
                    for (const field of this.campi_obbligatori)
                        if (!this.data[field])
                            this.focusDeep(this.$refs, field);
                    const destFields = ["TXDDE", "TXDIN", "TXDLO", "TXDPV"];
                    for (const [i, destinatario] of this.destinatari.entries())
                        for (let field of destFields)
                            if (!destinatario[field])
                                this.focusDeep(this.$refs, field + "_" + i);
                    this.resend = true;
                }
                return res;
            });
        },
        checkUncompleted() {
            /****
            * Elimino l'obbligatorietà dei campi come richiesto da Michele Maggio 2024
            * //let checkDestinazioneFields = this.destinatari.map(d => d.TXDCA && d.TXDLO && d.TXDPV && (d.TXCOL || d.TXPES || d.TXVOL || d.TXFLR)).reduce((x, y) => x && y);
            */
            let checkDestinazioneFields = this.destinatari.map(d => d.TXCOL || d.TXPES || d.TXVOL || d.TXFLR).reduce((x, y) => x && y);
            const flag = this.campi_obbligatori.map(t => this.data[t] && this.data[t].length > 0).reduce((x, y) => x && y) && checkDestinazioneFields;
            return flag;
        },
        clean() {
            this.data.TXDAT = null;
            this.data.TXSSE = null;
            this.data.TXMFI = null;
            this.data.TXMCD = null;
            this.data.TXMCN = null;
            this.data.TXMDE = null;
            this.data.TXMCA = null;
            this.data.TXMIN = null;
            this.data.TXMLO = null;
            this.data.TXMPV = null;
            this.destinatari = [];
            this.addDestinatario();
            this.mitt_auto_complete = this.initAutoComplete();
        },
        initAutoComplete() {
            return {
                OFCAP: {
                    text: '',
                    selectedOption: []
                },
                OFLOC: {
                    text: '',
                    selectedOption: []
                },
                OFCPD: {
                    selectedOption: []
                },
            };
        }
    }
}
</script>
