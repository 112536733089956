<template>
    <span class="inline p-2 w-full bg-red-500 flex justify-end">
        <button class="p-2 inline bg-white border text-red-700 focus:outline-none border-red-700 rounded" @click="$emit('show', !shown)">
            {{shown ? 'nascondi' : 'mostra'}} filtro {{shown ? '&#8679;' : '&#8681;'}}
        </button>
    </span>
</template>
<script>
export default {
    props: {
        shown: Boolean
    }
}
</script>