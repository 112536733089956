var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-1 min-w-0"},[_c('h2',{staticClass:"mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight"},[_vm._v("\n        "+_vm._s(_vm.__("Anagrafiche Clienti"))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"mx-auto sm:px-6 py-6"},[(_vm.cliente)?_c('div',[_c('SetUser',{attrs:{"user":_vm.cliente,"role":"client","newUser":_vm.newUser},on:{"createUser":_vm.createUser,"annulla":_vm.clear}})],1):_c('div',[_c('GeneralFilter',{attrs:{"in_rows":[
                [
                    {
                        type: 'auto_complete',
                        title: _vm.__('Filiale'), 
                        params: ['CLFIL']
                    },
                    {
                        type: 'auto_complete',
                        title: _vm.__('Codice cliente'), 
                        params: ['CLCOD']
                    }
                ],
                [
                    {
                        type: 'auto_complete',
                        title: _vm.__('Ragione Sociale'), 
                        params: ['CLBRE']
                    },
                    {
                        type: 'auto_complete',
                        title: _vm.__('P. IVA'), 
                        params: ['CLPIV']
                    }
                ],
                [
                    {
                        type: 'auto_complete',
                        title: _vm.__('Username'), 
                        params: ['username']
                    },
                    {
                        title: _vm.__('Stato Attivazione'),
                        type: 'select_option',
                        src: 'local',
                        mapOptionTitle: function (option) { return option[0].label; },
                        header: _vm.__('Tutti'),
                        params: [
                        [{
                            operator: 'IS NOT',
                            field: 'username',
                            type: 'text',
                            value: 'NULL',
                            label: _vm.__('Attivi')
                        }],
                        [{
                            operator: 'IS',
                            field: 'username',
                            type: 'text',
                            value: 'NULL',
                            label: _vm.__('Non Attivi')
                        }]
                        ]
                    }
                ]
            ],"url":_vm.$store.getters.uri+'/params-clienti'},on:{"search":_vm.search}}),_vm._v(" "),_c('Table',{attrs:{"items":[
                {label: _vm.__('Codice'), value: 'codice', type: 'combination',
                    map: function (obj) { return obj['CLFIL']+_vm.padCode(obj['CLCOD']); },
                    subItems: ['CLFIL', 'CLCOD'],
                },
                {label: _vm.__('RAGIONE SOCIALE'), value: 'CLBRE', type: 'text'},
                {label: _vm.__('P. IVA'), value: 'CLPIV', type: 'text'},
                {label: _vm.__('Username'), value: 'username', type: 'text', denyOrd:true},
                {label: _vm.__('Scegli'), values: function (obj) { return obj.new ? ['Crea'] : ['Modifica', 'Elimina']; }, type: 'buttons', onclick: [_vm.scegliCliente, _vm.eliminaCliente]}
            ],"result":_vm.result,"name":"Clienti"},on:{"searchPage":_vm.searchPage,"orderBy":_vm.orderBy}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }