<template>
    <div class="flex-1 min-w-0">
        <h2 class="mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight">
            {{__("Eventi Tracking")}}
        </h2>
        <div>
            <div class="mx-auto sm:px-6">
                <div v-for="(trackingEvent, i) in trackingEvents" :key="i">
                    <form class="p-6 m-6 border border-black rounded" @submit.prevent="updateEvent(i)">
                        <label class="block uppercase tracking-wide text-gray-700 font-bold mb-2">
                            {{ trackingEvent.name }}
                        </label>
                        <div class="border border-black rounded p-2">
                            <label class="block tracking-wide text-gray-700 font-bold mb-2 text-xs">
                                {{__('Legenda')}}
                            </label>
                            <label v-for="(phrase, index) in trackingEvent.description_terms.split('\\n')" :key="index"
                                class="block tracking-wide text-gray-700 mb-2 text-s">
                                {{ phrase }}
                            </label>
                        </div>
                        <div class="flex flex-wrap py-2">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                {{ __('Titolo')}}
                            </label>
                            <textarea
                                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                v-model="trackingEvent.title">
                                    </textarea>
                        </div>
                        <div class="flex flex-wrap py-2">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                {{ __('Descrizione')}}
                            </label>
                            <textarea
                                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                v-model="trackingEvent.description">
                                    </textarea>
                        </div>
                        <div class="flex flex-wrap pt-2 justify-end">
                            <button type="submit"
                                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                                {{__('Aggiorna')}}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            trackingEvents: []
        }
    },
    async created() {
        await this.initTrackingEvents();
    },
    methods: {
        async updateEvent(i) {
            await this.storeLogTime(async () => {
                const res = await this.handleCallback(() => this.axios.post("/admin/update-tracking-event", this.trackingEvents[i]), true);
                await this.initTrackingEvents();
                return res;
            });
        },
        async initTrackingEvents() {
            await this.storeLogTime(async () => {
                const res = await this.handleCallback(() => this.axios.get("/admin/tracking-events"));
                this.trackingEvents = res.value || res;;
                return res;
            });
        }
    }
}
</script>