<template>
    <div class="flex-1 min-w-0">
        <h2 class="mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight">
            {{__("Menu Create")}}
        </h2>
        <div class="py-12">
            <div class="mx-auto sm:px-6">
                <div class="bg-white overflow-hidden shadow-xl sm:rounded-lg">
                    <div class="p-10">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="menutype">
                            {{ __('Menu Type')}}
                        </label>
                        <div>
                            <input type="radio" id="menutype1" name="menutype" value="menu" v-model="data.menuType">
                            <label class="mx-2" for="menutype1">Menu</label>
                            <input type="radio" id="menutype2" name="menutype" value="submenu" v-model="data.menuType">
                            <label class="mx-2" for="menutype2">SubMenu</label>
                        </div>
                    </div>
                    <form class="p-10" @submit.prevent="menuCreate">
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class=" md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="name">
                                    {{ __('Name')}}
                                </label>
                                <input
                                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="name" name="name" type="text" v-model="data.name" required>

                            </div>
                            <div class="md:w-1/2 px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="link">
                                    {{ __('link')}}
                                </label>
                                <input
                                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="link" name="link" type="text" v-model="data.link" required>
                            </div>
                        </div>
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class=" md:w-1/3 px-3 mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="status">
                                    {{ __('status')}}
                                </label>
                                <!-- <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="status" name="status" type="text" required> -->
                                <div>
                                    <input type="radio" id="status1" name="status" v-model="data.status"
                                        value="Enabled">
                                    <label for="status1">Enabled</label>
                                    <input type="radio" id="status2" name="status" v-model="data.status"
                                        value="Disabled">
                                    <label for="status2">Disabled</label>
                                </div>
                            </div>
                            <div class="md:w-1/3 px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="type">
                                    {{ __('type')}}
                                </label>
                                <div>
                                    <input type="radio" id="type1" name="type" value="admin" v-model="data.type">
                                    <label for="type1">Admin</label>
                                    <input type="radio" id="type2" name="type" value="courier" v-model="data.type">
                                    <label for="type2">Courier</label>
                                    <input type="radio" id="type3" name="type" value="client" v-model="data.type">
                                    <label for="type3">Client</label>
                                    <input type="radio" id="type4" name="type" value="operator" v-model="data.type">
                                    <label for="type4">Operator</label>
                                </div>
                            </div>

                            <div v-if="data.menuType === 'submenu'" id="mydiv" class=" md:w-1/3 px-3 mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="menu_item_id">
                                    {{ __('Select Menu Item')}}
                                </label>
                                <div>
                                    <select id="menu_item_id" name="menu_item_id" required="menu_item_id"
                                        v-model="data.menu_item_id">
                                        <option v-for="(item, index) in menuItems" :key="index" name="menu_item_id"
                                            :value="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="flex inline justify-end items-center w-full">
                                <button
                                    class="mt-4 focus:outline-none text-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded"
                                    type="submit">
                                    {{ __('Submit')}}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            data: {
                menuType: 'menu',
                name: undefined,
                link: undefined,
                status: 'Enabled',
                type: 'admin',
                menu_item_id: undefined,
            }
        }
    },
    computed: {
        menuItems() {
            return this.$store.state.menuItems.filter(item => item.type === this.data.type);
        }
    },
    methods: {
        async menuCreate() {
            await this.storeLogTime(async () => {
                let res = await this.handleCallback(() => this.axios.post('/storemenu', this.data), true);
                if (res) {
                    this.$store.commit('initMenuItems');
                }
                return res;
            });
        }
    }
}
</script>