var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-1 min-w-0"},[_c('h2',{staticClass:"mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight"},[_vm._v(_vm._s(_vm.__("Spedizioni")))]),_vm._v(" "),_c('div',{staticClass:"py-12"},[_c('div',{staticClass:"mx-auto sm:px-6"},[_c('GeneralFilter',{attrs:{"in_rows":[
                [
                    {
                        type: 'date',
                        title: _vm.__('Data inizio'),
                        filter: {
                            operator: '>=',
                            field: 'T05_BODTB',
                            type: 'data'
                        },
                        min: _vm.minDate,
                        max: _vm.maxDate,
                        text: _vm.fromDate
                    },
                    {
                        type: 'date',
                        title: _vm.__('Data fine'),
                        filter: {
                            operator: '<=',
                            field: 'T05_BODTB',
                            type: 'data'
                        },
                        min: _vm.minDate,
                        max: _vm.maxDate,
                        text: _vm.maxDate
                    }
                ],
                [
                    {
                        type: 'text_input',
                        title: _vm.__('Nome mittente'),
                        filter: {
                            operator: 'like',
                            field: 'T05_BOMIT',
                            type: 'text'
                        },
                    },
                    {
                        type: 'text_input',
                        title: _vm.__('Riferimento mittente'),
                        filter: {
                            operator: 'like',
                            field: 'T05_BORMI',
                            type: 'text'
                        }
                    },
                    {
                        type: 'select_option',
                        title: _vm.__('Provincia di partenza'),
                        src: 'remote',
                        mapOptionTitle: function (option) { return option[0].value; },
                        field: 'T05_BOMPR'
                    }
                ],
                [
                    {
                        type: 'text_input',
                        title: _vm.__('Nome destinatario'),
                        filter: {
                            operator: 'like',
                            field: 'T05_BODIT',
                            type: 'text'
                        }
                    },
                    {
                        type: 'text_input',
                        title: _vm.__('Località destinatario'),
                        filter: {
                            operator: 'like',
                            field: 'T05_BODLO',
                            type: 'text'
                        }
                    },
                    {
                        type: 'select_option',
                        title: _vm.__('Provincia destinatario'),
                        src: 'remote',
                        mapOptionTitle: function (option) { return option[0].value; },
                        field: 'T05_BODPR'
                    }
                ],
                [
                    {
                        title: _vm.__('Pratica'),
                        type: 'select_option',
                        src: 'local',
                        mapOptionTitle: function (option) { return option[0].label; },
                        header: _vm.__('Tutti'),
                        params: [
                            [
                                {
                                    operator: '>=',
                                    field: 'T05_BOT33',
                                    type: 'text',
                                    value: '0',
                                    label: _vm.__('Solo Pratica')
                                }
                            ],
                            [
                                {
                                    operator: '<',
                                    field: 'T05_BOT33',
                                    type: 'text',
                                    value: '0',
                                    label: _vm.__('Escludi Pratica')
                                }
                            ]
                        ]
                    },
                    {
                        title: _vm.__('Contrassegno'),
                        type: 'select_option',
                        src: 'local',
                        mapOptionTitle: function (option) { return option[0].label; },
                        header: _vm.__('Tutti'),
                        params: [
                            [
                                {
                                    operator: '>',
                                    field: 'T05_CONTRASSEG',
                                    type: 'text',
                                    value: '0',
                                    label: _vm.__('Solo Contrassegno')
                                }
                            ],
                            [
                                {
                                    operator: '<=',
                                    field: 'T05_CONTRASSEG',
                                    type: 'text',
                                    value: '0',
                                    label: _vm.__('Escludi Contrassegno')
                                }
                            ]
                        ]
                    },
                    {
                        title: _vm.__('Giacenza'),
                        type: 'select_option',
                        src: 'local',
                        mapOptionTitle: function (option) { return option[0].label; },
                        header: _vm.__('Tutti'),
                        params: [
                            [
                                {
                                    operator: '>=',
                                    field: 'T05_BOT18',
                                    type: 'text',
                                    value: '0',
                                    label: _vm.__('Solo Giacenza')
                                }
                            ],
                            [
                                {
                                    operator: 'REGEX',
                                    field: 'concatenated_T02_TAPRF',
                                    type: 'text',
                                    value: ',18,',
                                    label: _vm.__(
                                        'Aperta senza disposizioni'
                                    )
                                },
                                {
                                    operator: 'NOT REGEX',
                                    field: 'concatenated_T02_TAPRF',
                                    type: 'text',
                                    value: ',20,|,25,',
                                    label: _vm.__(
                                        'Aperta senza disposizioni'
                                    )
                                }
                            ],
                            [
                                {
                                    operator: 'REGEX',
                                    field: 'concatenated_T02_TAPRF',
                                    type: 'text',
                                    value: ',25,',
                                    label: _vm.__('Aperta con disposizioni')
                                },
                                {
                                    operator: 'NOT REGEX',
                                    field: 'concatenated_T02_TAPRF',
                                    type: 'text',
                                    value: ',20,',
                                    label: _vm.__('Aperta con disposizioni')
                                }
                            ],
                            [
                                {
                                    operator: 'REGEX',
                                    field: 'concatenated_T02_TAPRF',
                                    type: 'text',
                                    value: ',20,',
                                    label: _vm.__('Chiusa')
                                }
                            ],
                            [
                                {
                                    operator: '<',
                                    field: 'T05_BOT18',
                                    type: 'text',
                                    value: '0',
                                    label: _vm.__('Escludi Giacenza')
                                }
                            ]
                        ]
                    }
                ]
            ],"url":_vm.$store.getters.uri + '/params-spedizioni'},on:{"search":_vm.search}}),_vm._v(" "),_c('Table',{attrs:{"items":[
                {
                    label: _vm.__('Sped.'),
                    value: 'id_spedizione',
                    type: 'combination',
                    map: function (obj) { return (obj['T05_BOANB'] + '').padStart(2, '0') +
                        (obj['T05_BOFBO'] + '').padStart(2, '0') +
                        (obj['T05_BOKEY'] + '').padStart(7, '0'); },
                    popupTitle: function (obj) { return 'ID Spedizione: ' +
                        (obj['T05_BOANB'] + '').padStart(2, '0') +
                        (obj['T05_BOFBO'] + '').padStart(2, '0') +
                        (obj['T05_BOKEY'] + '').padStart(7, '0'); },
                    subItems: ['T05_BOANB', 'T05_BOFBO', 'T05_BOKEY'],
                    clickable: true,
                    onclick: _vm.onClickEvent()
                },
                {
                    label: _vm.__('Rif. Mittente'),
                    value: 'T05_BORMI',
                    type: 'text'
                },
                {
                    label: _vm.__('Mittente'),
                    value: 'T05_BOMIT',
                    type: 'text'
                },
                {
                    label: _vm.__('Destinatario'),
                    value: 'T05_BODIT',
                    type: 'text'
                },
                {
                    label: _vm.__('Provincia Destinatario'),
                    value: 'T05_BODPR',
                    type: 'text',
                }
                ,
                {
                    label: _vm.__('Data Sped'),
                    value: 'T05_BODTB',
                    type: 'text'
                },
                {
                    label: _vm.__('Giacenza'),
                    value: 'giacenza',
                    type: 'combination',
                    denyOrd: true,
                    map: this.mapGiacenza(),
                    subItems: ['EVENTI']
                },
                {
                    label: _vm.__('Pratica'),
                    value: 'T05_BOT33',
                    type: 'text'
                },
                {
                    label: _vm.__('Prevista Consegna'),
                    value: 'prevista_consegna',
                    type: 'combination',
                    denyOrd: true,
                    map: this.mapPrevConsegna(),
                    subItems: ['T05_BODCV', 'EVENTI']
                },
                {
                    label: _vm.__('Consegna Effettiva'),
                    value: 'consegna_effettiva',
                    type: 'combination',
                    denyOrd: true,
                    map: this.mapConsegnaEffettiva(),
                    subItems: ['T01_TBDAT', 'EVENTI']
                },
                {
                    label: _vm.__('POD'),
                    type: 'buttons',
                    enabled: function (obj) { return obj['POD_EXISTS']; },
                    onclick: [_vm.clickPod],
                    denyOrd: true,
                    values: function (e) { return [
                        '<svg xmlns=\'http://www.w3.org/2000/svg\' class=\'h-6 w-6\' fill=\'none\' viewBox=\'0 0 24 24\' stroke=\'currentColor\'> <path stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'2\' d=\'M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z\' /> </svg>'
                    ]; }
                }
            ],"result":_vm.result,"name":"Spedizioni"},on:{"searchPage":_vm.searchPage,"orderBy":_vm.orderBy,"setLoading":function (status) { return _vm.$emit('setLoading', status); },"notify":function (status, message) { return _vm.$emit('notify', status, message); }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }