var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-1 min-w-0"},[_c('h2',{staticClass:"mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight"},[_vm._v("\n        "+_vm._s(_vm.__("Storico POD"))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"py-12"},[_c('div',{staticClass:"mx-auto sm:px-6"},[_c('GeneralFilter',{attrs:{"in_rows":[
                [
                    {
                        type: 'date',
                        title: _vm.__('Data inizio'),
                        filter: {
                            operator: '>',
                            field: 'dateTime',
                            type: 'text',
                            value: _vm.fromDate
                        },
                        text: _vm.fromDate
                    },
                    {
                        type: 'date',
                        title: _vm.__('Data fine'),
                        filter: {
                            operator: '<',
                            field: 'dateTime',
                            type: 'text',
                            value: _vm.toDate
                        },
                        text: _vm.toDate
                    }
                ],
                [
                    {
                        type: 'auto_complete',
                        title: _vm.__('Codice spedizione'),
                        params: ['spedizione']
                    },
                    {
                        type: 'auto_complete',
                        title: _vm.__('Nome file'),
                        params: ['fileName']
                    }
                ]
            ],"all":true,"url":_vm.$store.getters.uri + '/params-storicopod'},on:{"search":_vm.search}}),_vm._v(" "),_c('Table',{attrs:{"items":[
                {
                    label: _vm.__('Spedizione'),
                    value: 'spedizione',
                    type: 'text'
                },
                {
                    label: _vm.__('Data Visualizzazione'),
                    value: 'dateTime',
                    type: 'text'
                },
                {
                    label: _vm.__('Nome File'),
                    value: 'fileName',
                    type: 'text'
                },
                {
                    label: _vm.__('POD'),
                    type: 'buttons',
                    onclick: [_vm.clickPod],
                    denyOrd: true,
                    values: function (e) { return [
                        '<svg xmlns=\'http://www.w3.org/2000/svg\' class=\'h-6 w-6\' fill=\'none\' viewBox=\'0 0 24 24\' stroke=\'currentColor\'> <path stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'2\' d=\'M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z\' /> </svg>'
                    ]; }
                }
            ],"result":_vm.result,"name":"Storico_POD"},on:{"searchPage":_vm.searchPage,"orderBy":_vm.orderBy}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }