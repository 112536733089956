<template>
    <div class="pt-8">
        <div v-if="result && result.data && result.data.length > 0">
            <div v-if="exportTable || $store.state.user.role == 'admin' || $store.state.user.canexport == '1'"
                class="flex inline justify-end items-center">
                <div class="p-2 border bg-red-500 rounded text-white">
                    <span class="m-4">
                        <span class="flex inline-flex items-center">
                            <input type="radio" class="form-radio" name="exportType" id="exportTypeXls" value="xls"
                                v-model="exportType">
                            <label class="ml-2 text-l" for="exportTypeXls">excel</label>
                        </span>
                        <span class="flex inline-flex items-center ml-6">
                            <input type="radio" class="form-radio" name="exportType" id="exportTypeCsv" value="csv"
                                v-model="exportType">
                            <label class="ml-2 text-l" for="exportTypeCsv">csv</label>
                        </span>
                    </span>
                    <button
                        class="text-center focus:outline-none bg-white hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-white hover:border-transparent rounded">
                        <download-excel :name="title" :data="toExport" :type="exportType">
                            {{ __('Esporta Tabella') }}
                        </download-excel>
                    </button>
                </div>
            </div>
            <div class="overflow-scroll shadow-xl sm:rounded-lg">
                <table class="bg-white w-full whitespace-no-wrapw-full whitespace-no-wrap">
                    <tr class="text-center font-bold">
                        <th class="border bg-red-500 text-white px-6 py-4">#</th>
                        <th v-for="(item, index) in items" :key="index" class="border bg-red-500 text-white">
                            <div @click="orderBy(item)" class="focus:outline-none m-2"
                                :class="{ 'cursor-pointer': !item.denyOrd }">
                                <div v-html="item.label.replace(/ /g, '<br>')">
                                </div>
                                <div v-if="!item.denyOrd">
                                    <div v-if="equals(item)">
                                        {{ lastOrder.ord ? '&darr;' : '&uarr;' }}
                                    </div>
                                    <div v-else class="text-black">
                                        &darr; &uarr;
                                    </div>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr v-for="(row, rowIndex) in result.data" :key="rowIndex">
                        <td class="border px-4">{{ (result.from ? result.from : 1) + rowIndex }}</td>
                        <td v-for="(item, itemIndex) in items" :key="itemIndex" class="border px-4 " :class="item.cssclass">

                            <div v-if="item.type === 'link'">
                                <router-link :to="item.link" target="_blank" v-html="item.value"></router-link>
                            </div>
                            <div v-else-if="item.type === 'buttons' && (!item.enabled || item.enabled(row))">
                                <button v-for="(value, index) in item.values(row)" :key="index"
                                    class="mt-4 focus:outline-none mr-2 text-center bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded"
                                    @click="item.onclick[index](row)" v-html="value"></button>
                            </div>
                            <div v-else-if="item.type === 'combination'">
                                <div class="focus:outline-none" :disable="!item.clickable"
                                    :class="{ 'cursor-pointer font-bold': item.onclick }"
                                    @click="openModal(item, row, item.popupTitle ? item.popupTitle(row) : item.map(row))"
                                    v-html="item.map(row)"></div>
                            </div>
                            <div v-else>
                                <div class="focus:outline-none" :disable="!item.clickable"
                                    :class="{ 'cursor-pointer font-bold': item.onclick }"
                                    @click="openModal(item, row, row[item.value])">{{ row[item.value] }}</div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <Paginator :paginator="result" @searchPage="(url) => this.$emit('searchPage', url)"></Paginator>
            <Modal :modal="modal"></Modal>
        </div>
        <div v-else-if="result && result.data && result.data.length == 0" class="text-center">
            <b>{{ __('Nessun risultato') }}</b>
        </div>
    </div>
</template>
<script>
import Paginator from './Paginator.vue';
import Modal from './Modal.vue';

export default {
    components: {
        Paginator,
        Modal
    },
    props: {
        exportTable: false,
        name: {
            type: String,
            default: 'export'
        },
        items: Array,
        result: Object
    },
    data() {
        return {
            exportType: 'xls',
            lastOrder: {
                fields: '',
                ord: true
            },
            modal: {
                show: false,
                title: '',
                body: [{ value: '...' }]
            }
        }
    },
    methods: {
        openModal(item, row, title) {
            if (item.onclick) {
                this.modal.title = title;
                this.modal.body = [{ value: '...' }];
                item.onclick(row).then(r => {
                    this.modal.body = r.map(x => {
                        return { value: x };
                    });
                    this.$set(this, 'modal', this.modal);
                });
                this.modal.show = true;
                this.$set(this, 'modal', this.modal);
            }
        },
        equals(item) {
            return item.type === 'text' ?
                this.lastOrder.fields[0] === item.value :
                item.type === 'combination' ?
                    this.equalsArray(this.lastOrder.fields, item.subItems) :
                    false;
        },
        equalsArray(a, b) {
            return Array.isArray(a) &&
                Array.isArray(b) &&
                a.length === b.length &&
                a.every((val, index) => val === b[index]);
        },
        orderBy(item) {
            if (!item.denyOrd) {
                let fields = item.type === 'text' ? [item.value] : item.type === 'combination' ? item.subItems : undefined;
                if (fields) {
                    let ord = this.equalsArray(this.lastOrder.fields, fields) ? !this.lastOrder.ord : true;
                    this.lastOrder = {
                        fields: fields,
                        ord: ord
                    };
                    this.$emit('orderBy', this.convertOrder());
                }
            }
        },
        convertOrder() {
            let finalOrderBy = Object.assign({}, this.lastOrder);
            finalOrderBy.ord = this.lastOrder.ord ? 'asc' : 'desc';
            return finalOrderBy;
        }
    },
    computed: {
        title() {
            let now = new Date();
            let suffix = '_' + now.toLocaleDateString() + '_' + now.toLocaleTimeString();
            return this.name + suffix + '.' + this.exportType;
        },
        toExport() {
            let exportables = [];
            this.result.data.forEach(row => {
                let exportable = {};
                this.items.forEach(item => {
                    if (!item.type || item.type === 'text') {
                        let itemValue = item.value;
                        exportable[itemValue] = row[itemValue] ?? '';
                    } else if (item.type === 'combination') {
                        exportable[item.value] = item.map(row);
                    }
                });
                exportables.push(exportable);
            });
            return exportables;
        }
    }
}
</script>

<style scoped lang="scss">

table > tr > th:nth-child(6),
table > tr > th:nth-child(7),
table > tr > th:nth-child(10),
table > tr > th:nth-child(11){
    width: 110px;
}

</style>
