<template>
    <div class="flex-1 min-w-0">
        <h2 class="mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight">
            {{__("Anagrafiche Clienti")}}
        </h2>
        <div class="mx-auto sm:px-6 py-6">
            <div v-if="cliente">
                <SetUser :user="cliente" role="client" :newUser="newUser" @createUser="createUser" @annulla="clear">
                </SetUser>
            </div>
            <div v-else>
                <GeneralFilter :in_rows="
                [
                    [
                        {
                            type: 'auto_complete',
                            title: __('Filiale'), 
                            params: ['CLFIL']
                        },
                        {
                            type: 'auto_complete',
                            title: __('Codice cliente'), 
                            params: ['CLCOD']
                        }
                    ],
                    [
                        {
                            type: 'auto_complete',
                            title: __('Ragione Sociale'), 
                            params: ['CLBRE']
                        },
                        {
                            type: 'auto_complete',
                            title: __('P. IVA'), 
                            params: ['CLPIV']
                        }
                    ],
                    [
                        {
                            type: 'auto_complete',
                            title: __('Username'), 
                            params: ['username']
                        },
                        {
                            title: __('Stato Attivazione'),
                            type: 'select_option',
                            src: 'local',
                            mapOptionTitle: (option) => option[0].label,
                            header: __('Tutti'),
                            params: [
                            [{
                                operator: 'IS NOT',
                                field: 'username',
                                type: 'text',
                                value: 'NULL',
                                label: __('Attivi')
                            }],
                            [{
                                operator: 'IS',
                                field: 'username',
                                type: 'text',
                                value: 'NULL',
                                label: __('Non Attivi')
                            }]
                            ]
                        }
                    ]
                ]" :url="$store.getters.uri+'/params-clienti'" @search="search"></GeneralFilter>
                <Table :items="[
                    {label: __('Codice'), value: 'codice', type: 'combination',
                        map: (obj) => obj['CLFIL']+padCode(obj['CLCOD']),
                        subItems: ['CLFIL', 'CLCOD'],
                    },
                    {label: __('RAGIONE SOCIALE'), value: 'CLBRE', type: 'text'},
                    {label: __('P. IVA'), value: 'CLPIV', type: 'text'},
                    {label: __('Username'), value: 'username', type: 'text', denyOrd:true},
                    {label: __('Scegli'), values: (obj) => obj.new ? ['Crea'] : ['Modifica', 'Elimina'], type: 'buttons', onclick: [scegliCliente, eliminaCliente]}
                ]" :result="result" name="Clienti" @searchPage="searchPage" @orderBy="orderBy"></Table>
            </div>
        </div>
    </div>
</template>
<script>
import GeneralFilter from './filters/GeneralFilter.vue';
import Table from './utility/Table.vue';
import SetUser from './utility/SetUser.vue';

export default {
    components: {
        GeneralFilter,
        Table,
        SetUser
    },
    data() {
        return {
            result: undefined,
            data: undefined,
            lastSearch: undefined,
            order_by: undefined,
            cliente: undefined,
            newUser: undefined
        }
    },
    methods: {
        padCode(code) {
            return new String(code).padStart(6, '0');
        },
        async scegliCliente(obj) {
            await this.storeLogTime(async () => {
                let res;
                if(obj.new) {
                    res = await this.handleCallback(() => this.axios.post(this.$store.getters.uri + '/create-user-cliente-template', { filiale: obj.CLFIL, codice_cliente: obj.CLCOD }));
                } else {
                    res = await this.handleCallback(() => this.axios.post(this.$store.getters.uri + '/finduser', { id: obj.id }));
                }
                this.cliente = res;
                this.newUser = obj.new;
                return res;
            });
        },
        async eliminaCliente(obj) {
            await this.storeLogTime(async () => {
                const res = await this.handleCallback(() => this.axios.post(this.$store.getters.uri + '/removeuser', { id: obj.id }), true);
                this.searchPage(this.lastSearch);
                return res;
            });
        },
        search(data) {
            this.data = data;
            this.lastSearch = this.$store.getters.uri + '/clienti';
            this.searchPage(this.lastSearch);
        },
        async searchPage(url) {
            await this.storeLogTime(async () => {
                let res = null;
                if (url) {
                    this.lastSearch = url;
                    this.data.order_by = this.order_by;
                    res = await this.handleCallback(() => this.axios.post(url, this.data));
                    this.$set(this, 'result', res);
                }
                return res;
            });
        },
        orderBy(ord) {
            this.order_by = ord;
            this.searchPage(this.lastSearch);
        },
        clear() {
            this.cliente = undefined;
        },
        async createUser(user, repeat_password) {
            await this.storeLogTime(async () => {
                let res = null;
                if (user.password === repeat_password) {
                    res = await this.handleCallback(() => this.axios.post(this.$store.getters.uri + '/setuser', { data: user }), true);
                    if (res) {
                        this.searchPage(this.lastSearch);
                        this.clear();
                    }
                } else
                    this.$emit('notify', false, this.__('Password differenti'));
                return res;
            });
        }
    }
}
</script>