import Vue from 'vue'
import Vuex from 'vuex';
import axios from 'axios';
import router from './router';

const jwtKey = "jwt";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        user: undefined,
        jwt: undefined,
        remember: false,
        menuItems: [],
        show_consenso_webtracking: false,
        show_consenso_pod: false,
        show_dhl_tracking_link: false,
        loggingOut: false
    },
    getters: {
        uri(state) {
            if(state.user) return "/"+state.user.role;
            else router.replace('/login');
        }
    },
    mutations: {
        show_consenso_webtracking(state, flag) {
            state.show_consenso_webtracking = flag;
        },
        show_consenso_pod(state, flag) {
            state.show_consenso_pod = flag;
        },
        show_dhl_tracking_link(state, flag) {
            state.show_dhl_tracking_link = flag;
        },
        setHeader(state) {
            axios.defaults.headers['Authorization'] = 'Bearer ' + state.jwt;
        },
        async login(state, jwt) {
            state.jwt = jwt;
            localStorage.setItem(jwtKey, jwt);
            this.commit('loadUser');
            this.commit('show_consenso_webtracking', !state.user.consenso_webtracking);
            this.commit('show_dhl_tracking_link', state.user.show_dhl_tracking_link);
        },
        async logout(state) {
            state.loggingOut = true;
            localStorage.removeItem(jwtKey);
            state.jwt = undefined;
            state.user = undefined;
            state.show_consenso_webtracking = false;
            state.show_consenso_pod = false;
            state.show_dhl_tracking_link = false;
            state.loggingOut = false;
        },

        getUser(state) {
            state.jwt = localStorage.getItem(jwtKey);
            this.commit('loadUser');
        },

        loadUser(state) {
            if (state.jwt) {
                let body = parseJwt(state.jwt);
                state.user = JSON.parse(body.user);



                state.remember = body.remember;
                this.commit('refreshUser');
                this.commit('initMenuItems');
            }
        },
        async refreshUser(state) {
            state.user = (await axios.get('/auth/user')).data;
            this.commit('show_dhl_tracking_link', state.user.show_dhl_tracking_link);
        },
        async initMenuItems(state) {
            this.commit('setHeader');
            let res = await axios.get("/menuitems");
            if(res.data) Vue.set(state, 'menuItems', res.data);
        },
    },
    actions: {
        async refresh({commit}) {
            try {
                let res = await axios.get('/auth/refresh');
                commit('login', res.data);
                commit('initMenuItems');
                return true;
            } catch(e) {
                return false;
            }
        }
    },
});

function parseJwt(jwt) {
    var base64Url = jwt.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function(c) {
                return (
                    "%" +
                    ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                );
            })
            .join("")
    );
    return JSON.parse(jsonPayload);
}

export default store;
