<template>
  <div v-if="paginator && paginator.current_page && paginator.last_page !== 1" class="bg-white px-4 flex items-center justify-between border-t border-gray-200 sm:px-6">
  <div class="flex-1 flex justify-between sm:hidden">
    <button :disabled="!paginator.prev_page_url" @click="searchPage(paginator.prev_page_url)" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
      &laquo;
    </button>
    <button :disabled="!paginator.next_page_url" @click="searchPage(paginator.next_page_url)" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
      &raquo;
    </button>
  </div>
  <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
    <div>
      <p class="text-sm text-gray-700">
        <span class="font-medium">{{paginator.current_page}}</span>
        /
        <span class="font-medium">{{paginator.last_page}}</span>
      </p>
    </div>
    <div>
      <nav 
        v-for="(page, index) in this.paginator.links" 
        :key="index" 
        class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" 
        aria-label="Pagination">
        <button 
          v-if="selectPage(index)"
          v-html="page.label"
          @click="searchPage(page.url)" 
          aria-currentPageent="page" 
          :class="{'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium': page.active}"  
          class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">
        </button>
      </nav>
    </div>
  </div>
</div>
</template>
<script>
export default {
    props: {
        paginator: Object
    },
    methods: {
        searchPage(url) {
          this.$emit('searchPage', url);
        },
        selectPage(index) {
          return index == 0 || 
            index == 1 || 
            this.paginator.links[index].label == '...' || 
            this.paginator.links[index].active || 
            index == this.paginator.links.length - 2 || 
            index == this.paginator.links.length - 1;
        }
    }
}
</script>