<template>
  <div v-if="extended" class="hidden sm:flex sm:items-center sm:ml-6">
    <div class="ml-3 relative">
      <div>
        <button @click="openDropdown = !openDropdown" type="button"
          class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
          id="user-menu-button" aria-expanded="false" aria-haspopup="true">
          <button v-if="user.profile_photo_url"
            class="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition">
            <img class="h-8 w-8 rounded-full object-cover" :src="user.profile_photo_url" :alt="user.Nome" />
          </button>
          <span v-else class="inline-flex rounded-md">
            <button type="button"
              class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:bg-gray-200 hover:text-gray-700 focus:outline-none transition">
              {{user.username}}

              <svg class="ml-2 -mr-0.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor">
                <path fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd" />
              </svg>
            </button>
          </span>
        </button>
      </div>
      <div v-show="openDropdown" @click="openDropdown = false"
        class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
        <div class="block px-4 py-2 text-xs text-gray-400">
          {{ __('Manage Account') }}
        </div>
        <router-link :to="{name: 'profile'}" class="block px-4 py-2 text-sm text-gray-700 focus:outline-none" role="menuitem"
          tabindex="-1" id="user-menu-item-0">
          {{ __('Profilo') }}
        </router-link>
        <button @click="logout()" class="block px-4 py-2 text-sm text-gray-700 focus:outline-none" role="menuitem" tabindex="-1"
          id="user-menu-item-1">
          {{ __('Log Out') }}
        </button>
      </div>
    </div>
  </div>

  <div v-else>
    <div>
      <div v-if="user.profile_photo_url" class="flex-shrink-0 mr-3">
        <img class="h-10 w-10 rounded-full object-cover" :src="user.profile_photo_url" :alt="user.Nome" />
      </div>
      <div v-else>
        <div class="block px-4 py-2 font-medium text-gray-500">{{ user.username }}</div>
      </div>
      <div class="block px-4 py-2 ">
        <div>
          <router-link to="/profile" class="block px-4 py-2 text-sm text-gray-900 focus:outline-none" role="menuitem"
            tabindex="-1" id="user-menu-item-0">
            {{ __('Profilo') }}
          </router-link>
        </div>
        <div>
          <button @click="logout()" class="block px-4 py-2 text-sm text-gray-900 focus:outline-none" role="menuitem" tabindex="-1"
            id="user-menu-item-1">
            {{ __('Log Out') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      extended: Boolean
    },
    data() {
      return {
        user: this.$store.state.user,
        openDropdown: false
      }
    },
    methods: {
      async logout() {
        await this.axios.post("/auth/logout");
        await this.$store.commit('logout');
        this.$router.push({ name: 'login' });
      },
      close(e) {
        if (!this.$el.contains(e.target)) {
          this.openDropdown = false;
        }
      }
    },
    mounted() {
      document.addEventListener('click', this.close);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.close);
    }
  }
</script>
