<template>
    <div class="flex-1 min-w-0">
        <h2 class="mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight">
            {{__("Profilo")}}
        </h2>
        <div class="py-6">
            <div class="mx-auto sm:px-6">
                <div class="bg-white overflow-hidden shadow-xl sm:rounded-lg">
                    <h2 class="mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight">
                        {{__("Cambia Password")}}
                    </h2>
                    <form class="p-10" @submit.prevent="changePassword">
                        <div class="flex flex-wrap">
                            <div class=" md:w-1/2 px-3 my-4 md:mb-0 w-full">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="newPassword">
                                    {{ __('Nuova Password')}}
                                </label>
                                <input
                                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="newPassword" name="newPassword" v-model="data.newPassword" type='password'>
                            </div>
                            <div class="md:w-1/2 px-3 my-4 w-full">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="repeatNewPassword">
                                    {{ __('Ripeti Nuova Password')}}
                                </label>
                                <input
                                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="repeatNewPassword" name="repeatNewPassword" v-model="data.repeatNewPassword"
                                    type="password">
                            </div>
                        </div>
                        <div class="flex flex-wrap">
                            <div class="md:w-1/2 px-3 my-4 md:mb-0 w-full">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="currentPassword">
                                    {{ __('Password Corrente')}}
                                </label>
                                <input
                                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="currentPassword" name="currentPassword" v-model="data.currentPassword"
                                    type="password">
                            </div>
                            <div class="md:w-1/2 px-3 mb-6 md:mb-0 flex inline justify-end items-center w-full">
                                <button
                                    class="mt-4 focus:outline-none text-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded"
                                    type="submit">
                                    {{ __('Cambia')}}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            data: {
                newPassword: undefined,
                repeatNewPassword: undefined,
                currentPassword: undefined
            }
        }
    },
    methods: {
        async changePassword() {
            await this.storeLogTime(async () => {
                const res = await this.handleCallback(() => {
                    let res = this.axios.post('/auth/changepassword', this.data);
                    return res;
                }, true);
                this.clear();
                return res;
            });
        },
        clear() {
            this.data.newPassword = undefined;
            this.data.repeatNewPassword = undefined;
            this.data.currentPassword = undefined;
        }
    }
}
</script>