<script>
export default {
  data() {
    return {
      dictionary: [],
      imports: [
        // Import dictionary name
        // 'totale'
      ]
    }
  },
  created() {
    this.imports.forEach(i => {
      let list = require('./dictionary/' + i).default;
      if(list) list.forEach(el => {
        this.dictionary.push(el);
      });
    });
  },
  methods: {
    __(key) {
      for(let i = 0; i < this.dictionary.length; i++) {
        let found = this.dictionary[i][0];
        if(found === key)
          return this.dictionary[i][1];
      }
      return key;
    }
  }
}
</script>