<template>
    <div class="flex-1 min-w-0">
      <div class="flex flex-wrap justify-center mt-4">
        <div name="logo" class="w-4/12 flex">
          <img src="/img/TardiniLogo.svg"/>
        </div>
      </div>
        <div v-if="rifMittente">
      <div class="flex flex-wrap justify-center uppercase text-center">
          <h2 class="mt-4 text-xl text-gray-800 leading-tight">
              {{__("Dettagli riferimento mitttente")}}
          </h2>
        </div>
        <div class="flex flex-wrap justify-center uppercase font-semibold text-center">
          <h2 class="mt-4 text-xl text-gray-800 leading-tight">
              {{$route.query.id}}
          </h2>
        </div>
            <div class="mx-auto sm:px-6 py-12">
              <div class="overflow-scroll shadow-xl sm:rounded-lg">
            <table class="bg-white w-full whitespace-no-wrapw-full whitespace-no-wrap">
                <tr>
                    <th class="border bg-red-500 text-white px-6 py-4">
                      {{__('Codice')}}
                    </th>
                    <td class="border px-4">
                        {{(rifMittente.T05_BOANB+'').padStart(2,'0')+(rifMittente.T05_BOFBO+'').padStart(2,'0')+(rifMittente.T05_BOKEY+'').padStart(7,'0')}}
                    </td>
                </tr>
                <tr>
                    <th class="border bg-red-500 text-white px-6 py-4">
                      {{__('Mittente')}}
                    </th>
                    <td class="border px-4">
                        {{rifMittente.T05_BOMIT}}<br/>
                        {{rifMittente.T05_BOMIN+' - '+rifMittente.T05_BOMLO+' ('+rifMittente.T05_BOMPR+')'}}
                    </td>
                </tr>
                <tr v-if="rifMittente.T05_BODCV && rifMittente.T05_BODCV.length > 0">
                    <th class="border bg-red-500 text-white px-6 py-4">
                      {{__('Data di arrivo')}}
                    </th>
                    <td class="border px-4">
                        {{rifMittente.T05_BODCV}}
                    </td>
                </tr>
                <tr v-if="rifMittente.T05_COLLI && rifMittente.T05_COLLI.length > 0">
                    <th class="border bg-red-500 text-white px-6 py-4">
                      {{__('Colli')}}
                    </th>
                    <td class="border px-4">
                        {{parseInt(rifMittente.T05_COLLI)}}
                    </td>
                </tr>
                <tr v-if="rifMittente.T05_PESO && rifMittente.T05_PESO > 0">
                    <th class="border bg-red-500 text-white px-6 py-4">
                      {{__('Peso')}}
                    </th>
                    <td class="border px-4">
                        {{rifMittente.T05_PESO}}
                    </td>
                </tr>
                <tr v-if="rifMittente.T05_METRICUBI && rifMittente.T05_METRICUBI > 0">
                    <th class="border bg-red-500 text-white px-6 py-4">
                      {{__('Volume')}}
                    </th>
                    <td class="border px-4">
                        {{rifMittente.T05_METRICUBI}} 
                    </td>
                </tr>
                <tr v-if="rifMittente.T05_CONTRASSEG && rifMittente.T05_CONTRASSEG.length > 0">
                    <th class="border bg-red-500 text-white px-6 py-4">
                      {{__('Valore')}}
                    </th>
                    <td class="border px-4">
                        {{parseFloat(rifMittente.T05_CONTRASSEG)}} €
                    </td>
                </tr>
                <tr>
                    <th class="border bg-red-500 text-white px-6 py-4">
                      {{__('Stato')}}
                    </th>
                    <td class="border px-4">
                      <p v-for="(evento, index) in filteredEvants" :key="index">
                        {{evento.T01_TBDAT+' - '+ evento.T02_DESCRIZION+(evento.T04_DESCR_ZONA.length>0?': '+evento.T04_DESCR_ZONA:'')}}
                      </p>
                    </td>
                </tr>
            </table>
            </div>
            </div>
        </div>
        <div v-else>
          <div class="flex flex-wrap justify-center uppercase font-semibold text-center">
            <h2 class="mt-4 text-xl text-red-700 leading-tight">
                {{__('numero ddt "'+$route.query.id+'" non trovato')}}
            </h2>
          </div>
        </div>
      </div>
</template>
<script>
export default {
  data() {
    return {
      rifMittente: null
    }
  },
  async created() {
    await this.storeLogTime(async () => {
      const res = await this.handleCallback(() => this.axios.get('/rif-mittente?code='+this.$route.query.code+'&id='+this.$route.query.id));
      this.rifMittente = res.value || res;;
      return res;
    });
  },
  computed: {
    filteredEvants() {
      return this.rifMittente.EVENTI.filter(e => e['T02_TAPRF'] != '05' || e['T02_TAPRO'] != '130');
    }
  }
}
</script>