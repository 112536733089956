<script>
export default {
    methods: {
        initHeader() {
            this.$store.commit('getUser');
        },

        async storeLogTime(callback) {
            const start_vue_render = new Date();
            let results = await callback();
            const end_vue_render = new Date();
            try {
                if (results) {
                    const log_infos = [];
                    if (!Array.isArray(results)) {
                        log_infos.push(results.log_info);
                    } else {
                        results.forEach(result => log_infos.push(result.log_info))
                    }
                    for (const log_info of log_infos) {
                        if (log_info && log_info.log_api_calls_shipments == 1) {
                            await this.axios.post('store_render_times', {
                                request_api_logger_id: log_info.request_api_logger_id,
                                start_vue_render: start_vue_render,
                                end_vue_render: end_vue_render
                            });
                        }
                    }
                }
            } catch (e) {
                console.error(e)
            }
        },

        async handleCallback(callback, successNotification = false, errorNotification = true, loading = true) {
            this.initHeader();
            let result = false;
            if (loading) this.$emit('setLoading', true);
            try {
                let response = await callback();
                result = response ? response.data : '';
                if (successNotification) this.$emit('notify', true, result.message ? result.message : response.statusText);
            } catch (e) {
                result = await this.errorHandle(e, errorNotification, callback);
            }
            if (loading) this.$emit('setLoading', false);
            return result;
        },

        async errorHandle(e, errorNotification, callback) {
            let result = false;
            if (e.response) {
                let response = e.response;
                if (response.status === 401) {
                    result = await this.unauthorizedHandle(callback);
                } else if (response.status === 400 && response.data.message && response.data.message.includes('consenso_webtracking')) {
                    this.$store.commit('show_consenso_webtracking', true);
                } else if (response.status === 400 && response.data.message && response.data.message.includes('consenso_pod')) {
                    this.$store.commit('show_consenso_pod', true);
                } else if (errorNotification) {
                    console.log("server/" + response.statusText + ": " + response.data.message);
                    this.$emit('notify', false, "Errore", response.data.message);
                }
            } else if (errorNotification) {
                console.log("local/error: " + e.message);
                this.$emit('notify', false, "Errore", "Si è verificato un problema. Si prega di riprovare. In caso il problema persiste contattare il supporto tecnico.");
            }
            return result;
        },

        async unauthorizedHandle(callback) {
            let result = false;
            // if (await this.$store.dispatch('refresh')) {         // <- refresh
            //     result = (await callback()).data;
            // } else {
                this.$store.commit('logout');
                this.$router.push('/login');
            // }
            return result;
        },

        isMobile() {
            return (window.innerWidth <= 768) || (window.innerHeight <= 768);
        }
    }
}
</script>