import VueRouter from 'vue-router';
import store from './store';
import axios from 'axios';

import Login from './views/Login';
import Dashboard from './views/Dashboard';
import Profile from './views/Profile';
import MenuCreate from './views/MenuCreate';
import Spedizioni from './views/Spedizioni';
import SpedizioniMin from './views/SpedizioniMin';
import AggiungiPrenotazione from './views/AggiungiPrenotazione';
import VisualizzaPrenotazioni from './views/VisualizzaPrenotazioni';
//import ForgotPassword from './views/ForgotPassword';
//import ResetPassword from './views/ResetPassword';
import Referta from './views/Referta';
import StoricoPOD from './views/StoricoPOD';
import HandleCliente from './views/HandleCliente';
import HandleCorriere from './views/HandleCorriere';
import HandleVisualizzaPESATE from './views/HandleVisualizzaPESATE.vue';
import HandleStoricoPOD from './views/HandleStoricoPOD';
import HandleTrackingEvents from './views/HandleTrackingEvents';
import HandleMailNotifications from './views/HandleMailNotifications';
import HandleNotifications from './views/HandleNotifications';
import HandleFiliali from './views/HandleFiliali';
import Index2RifMit from './views/Index2RifMit';


const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
  },
  {
    path: '/menucreate',
    name: 'menucreate',
    component: MenuCreate,
  },
  {
    path: '/spedizioni',
    name: 'spedizioni',
    component: Spedizioni,
  },
  {
    path: '/spedizioni-min',
    name: 'spedizioni-min',
    component: SpedizioniMin,
  },
  {
    path: '/referta',
    name: 'referta',
    component: Referta,
  },
  {
    path: '/aggiungiprenotazione',
    name: 'aggiungiprenotazione',
    component: AggiungiPrenotazione,
  },
  {
    path: '/visualizzaprenotazioni',
    name: 'visualizzaprenotazioni',
    component: VisualizzaPrenotazioni,
  },
  {
    path: '/storicopod',
    name: 'storicopod',
    component: StoricoPOD,
  },
  {
    path: '/clienti',
    name: 'clienti',
    component: HandleCliente,
  },
  {
    path: '/corrieri',
    name: 'corrieri',
    component: HandleCorriere,
  },
  {
    path: '/visualizzapesate-admin',
    name: 'visualizzapesate-admin',
    component: HandleVisualizzaPESATE,
  },
  {
    path: '/storicopod-admin',
    name: 'storicopod-admin',
    component: HandleStoricoPOD,
  },
  {
    path: '/tracking-events',
    name: 'tracking-events',
    component: HandleTrackingEvents,
  },
  {
    path: '/mail-notifications',
    name: 'mail-notifications',
    component: HandleMailNotifications,
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: HandleNotifications,
  },
  {
    path: '/filiali',
    name: 'filiali',
    component: HandleFiliali,
  },
  {
    path: '/index2.php',
    name: 'index2.php',
    component: Index2RifMit,
  },
  /*{
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: ForgotPassword,
  },
  {
    path: '/resetpassword/:token',
    name: 'resetpassword',
    component: ResetPassword,
  },*/
  {
    path: '/*',
    name: 'others',
    redirect: '/dashboard'
  }
];

const router = new VueRouter({
  history: true,
  mode: 'history',
  routes,
});

let whitelist = [
  '/login',
  //'/index2.php'
  //'/forgotpassword',
  //'/resetpassword'
];

let whitelistLogged = [
  '/profile',
  '/dashboard',
  //'/index2.php'
];

async function checkAndRedirect(to, next, status = -1) {
  let goNext = false;
  if(status === 401 && store.state.remember)
    goNext = await (store.dispatch('refresh'));
  if (whitelist.includes(to.path) || goNext) {
      next();
  } else {
      store.commit("logout");
      next({ name: "login" });
  }
}

router.beforeEach(async (to, from, next) => {
  try {
    store.commit('getUser');
    //await axios.get('/auth/test');
    const menuItemsData = (await axios.get('/menuitems')).data;
    const menuItems = menuItemsData.map(d => d.link);
    menuItemsData.forEach(m => m.sub_menu_items.forEach(s => menuItems.push(s.link)));
    if(whitelistLogged.includes(to.path) || menuItems.includes(to.path))
      next();
    else
      next({name: 'dashboard'});
  } catch(e) {
    await checkAndRedirect(to, next, e.response ? e.response.status : -1);
  }
});

export default router;
