<template>
    <div class="flex-1 min-w-0">
        <h2 class="mt-10 ml-10 font-semibold text-xl text-gray-800 leading-tight">
            {{__("Referta")}}
        </h2>
        <div class="py-6">
            <div class="mx-auto sm:px-6">
                <div class="bg-white overflow-hidden shadow-xl sm:rounded-lg">
                    <div>
                        <form class="p-10" @submit.prevent="referta">
                            <div class="flex flex-wrap">
                                <div class="md:w-1/2 px-3 w-full my-4">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                        {{ __('Data')}}
                                    </label>
                                    <label v-if="$store.state.user.datetime_referencing == 1"
                                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 py-2">
                                        {{ data.DATA }}
                                    </label>
                                    <input v-else
                                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        v-model="data.DATA" type="date">
                                </div>
                                <div class="md:w-1/2 px-3 w-full my-4">
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                        {{ __('Ora')}}
                                    </label>
                                    <label v-if="$store.state.user.datetime_referencing == 1"
                                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 py-2">
                                        {{ data.ORA }}
                                    </label>
                                    <input v-else
                                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        v-model="data.ORA" type="time">
                                </div>
                            </div>
                            <div class="flex flex-wrap">
                                <div class="md:w-screen px-3 w-full">
                                    <div class="flex inline w-full justify-between align-middle">
                                        <label
                                            class="py-4 block uppercase tracking-wide text-gray-700 text-l font-bold mb-2">
                                            {{ __('Codici')}}
                                        </label>
                                        <div @click="openCameraMode()"
                                            class="md:invisible cursor-pointer text-center my-auto p-2 bg-white border text-red-700 focus:outline-none border-red-700 rounded">
                                            {{__('Apri Fotocamera')}}
                                        </div>
                                    </div>
                                    <div class="my-4 w-full" v-for="(code, index) in data.CODICI" :key="index">
                                        <div class="w-full flex inline">
                                            <input ref="codice"
                                                :class="colorByStatus(code.status)"
                                                minlength="11" maxlength="11"
                                                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded px-4 leading-tight focus:outline-none focus:border-gray-500"
                                                @input="addCodice($event.target.value, index)" @click="indexScanner = index"
                                                v-model="code.text" type="text">
                                            <svg v-if="code.text.length > 0" @click="cancelCode(index)" xmlns="http://www.w3.org/2000/svg"
                                                class="m-2 cursor-pointer h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z" />
                                            </svg>
                                        </div>
                                        <label>{{code.statusText}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-wrap text-center items-center">
                                <div class="md:w-1/3 px-3 w-full my-4 inline-block">
                                    <!--
                                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                        {{ __('Corrispondente')}}
                                    </label>

                                    <label class="uppercase text-xl font-bold">
                                        {{$store.state.user.BOCOR}}
                                    </label>-->
                                </div>
                                <div class="md:w-1/3 px-3 w-full my-4">
                                    <AutoComplete :title="__('Esito Evento')" :url="$store.getters.uri+'/events-params'"
                                        :text="auto_complete.EVENTO.text"
                                        :selectedOption="auto_complete.EVENTO.selectedOption"
                                        :mapOptionTitle="(option) => option.length > 0 ? option[0].value : ''" @chosen="(c) => {
                                            auto_complete.EVENTO.text = c.text;
                                            auto_complete.EVENTO.selectedOption = c.selectedOption;
                                            if(auto_complete.EVENTO.selectedOption)
                                                data.EVENTO = auto_complete.EVENTO.selectedOption;
                                            else
                                                data.EVENTO = null;
                                        }"
                                        :searchOptions="(url, word) => searchBase(url, {params: ['DESCRIZION', 'TAPRF', 'TAPRO'], word: word})">
                                    </AutoComplete>
                                </div>
                                <div class="md:w-1/3 px-3 w-full my-4">
                                    <button
                                        class="mt-4 focus:outline-none text-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded"
                                        type="submit">
                                        {{ __('Referta')}}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div v-if="cameraMode">
                        <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
                            aria-modal="true">
                            <div
                                class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
                                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                                    aria-hidden="true"></div>
                                <div
                                    class="overflow-y-scroll inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full">
                                    <StreamBarcodeReader @decode="decode" ref="scanner" @loaded="$emit('setLoading', false)">
                                    </StreamBarcodeReader>
                                        <div class="bg-gray-50 px-4 p-2 sm:flex sm:flex-row-reverse">
                                            <button type="button" @click="closeCamera()"
                                                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                                                {{ __('Esci') }}
                                            </button>
                                        </div>
                                    <!--
                                    <div v-for="(code, index) in reverseCodici" :key="index">
                                        <div v-if="index != 0" class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                            <label ref="codice"
                                                :class="{'bg-green-200': code.status == 2, 'bg-yellow-200': code.status === 1, 'bg-red-200': code.status === 0}"
                                                class="appearance-none block w-full rounded px-4 leading-tight focus:outline-none"
                                                type="text">
                                                {{code.text}}
                                            </label>
                                            <label>{{code.statusText}}</label>
                                        </div>
                                    </div>
                                        -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AutoComplete from './filters/AutoComplete.vue';
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
    components: {
        AutoComplete,
        StreamBarcodeReader
    },
    computed: {
        reverseCodici() {
            return this.data.CODICI.slice().reverse();
        }
    },
    data() {
        return {
            auto_complete: {
                EVENTO: { text: '' }
            },
            already_scanned: [],
            data: {
                DATA: null,
                ORA: null,
                CODICI: [{ text: '' }],
                EVENTO: null
            },
            cameraMode: false,
            indexScanner: 0
        }
    },
    async created() {
        await this.$store.commit('refreshUser');
        this.updateDateTime();
        this.initEvents();
    },
    mounted() {
        this.$refs.codice[0].focus();
    },
    methods: {
        async initEvents() {
            const res = await this.searchBase(this.$store.getters.uri + '/events-params', { word: '', params: ['DESCRIZION', 'TAPRF', 'TAPRO'] });
            const evento = res.data[0][0];
            this.auto_complete.EVENTO.text = evento.value;
            this.auto_complete.EVENTO.selectedOption = [evento];
            this.data.EVENTO = res.data[0];
        },
        colorByStatus(status) {
            switch (status) {
                case 0: return 'bg-red-200';
                case 1: return 'bg-yellow-200';
                case 2: return 'bg-green-200';
            }
        },
        openCameraMode() {
            this.$emit('setLoading', true);
            this.cameraMode = true;
        },
        closeCamera() {
            this.$refs.scanner.codeReader.stream.getTracks().forEach(function (track) { track.stop() });
            this.cameraMode = false;
            this.already_scanned = [];
        },
        cancelCode(i) {
            this.data.CODICI.splice(i, 1);
        },
        async decode(decoded) {
            if (decoded && !this.already_scanned.includes(decoded)) {
                this.already_scanned.push(decoded);
                if (this.indexScanner == 0 || this.data.CODICI[this.indexScanner - 1].text != decoded) {
                    await this.addCodice(decoded, this.indexScanner);
                    this.indexScanner++;
                }
                if (this.$store.state.user.shoot_hesitate) {
                    await this.referta();
                }
                this.cameraMode = false;
            }
        },
        updateDateTime() {
            const now = new Date().toLocaleString().split(', ');
            const date = now[0].split('/');
            this.data.DATA = date[2].padStart(4, 0) +'-'+date[1].padStart(2, 0)+'-'+date[0].padStart(2, 0);
            this.data.ORA = now[1].substring(0, 5);
        },
        async addCodice(e, i) {
            this.data.CODICI[i].text = e;
            await this.validate(i);
            this.data.CODICI = this.data.CODICI.filter(c => c.text.length > 0);
            this.data.CODICI.push({ text: '' });
        },
        async validate(i) {
            if (this.data.CODICI[i].text.length == 11) {
                this.data.CODICI[i].status = undefined;
                this.$set(this.data.CODICI, i, this.data.CODICI[i]);
                const code = this.data.CODICI[i].text;
                let res = await this.searchBase(this.$store.getters.uri + '/refertabile', { code: code });
                this.data.CODICI[i].status = res.status;
                this.data.CODICI[i].statusText = res.statusText;
                // if (this.isMobile() && this.cameraMode) {
                //     this.$emit('openModal', {title: `Spedizione ${this.data.CODICI[i].text}: ${res.statusText}`, color: this.colorByStatus(res.status)});
                // }
            } else {
                this.data.CODICI[i].status = undefined;
                this.data.CODICI[i].statusText = '';
            }
            this.$set(this.data.CODICI, i, this.data.CODICI[i]);
        },
        createFilter(field, value) {
            return [{
                field: field,
                value: value,
                operator: '=',
                type: 'text'
            }];
        },
        notifyWrapper(text, flag=false, body=undefined) {
            if (this.isMobile()) {
                this.$emit('openModal', { title: text, body: body, color: flag ?  'bg-green-200' : 'bg-red-200' });
            } else {
                this.$emit('notify', flag, text);
            }
        },
        async referta() {
            await this.storeLogTime(async () => {
                let res = null;
                const checklist = [
                    [this.data.DATA != null, {'value':'- Data esito'}],
                    [this.data.ORA != null, {'value':'- Ora esito'}],
                    [this.data.EVENTO != null, {'value':'- Esito evento'}],
                    [this.data.CODICI.filter(c => c.text.length > 0).length > 0,  {'value':'- Codici spedizione'}]
                ];

                //Verifica se almeno 1 condizione specificata in checklist sia false
                if (checklist.find(v => !v[0])) {
                    //const text_checklist = checklist.filter(v => !v[0]).map(v => v[1].toUpperCase()).reduce((x, y) => `${x}${y}, `, '');
                    let arrErr = checklist.filter(v => !v[0]).map(v => v[1]);
                    this.notifyWrapper(this.__(`Compila i seguenti campi:`), false, arrErr);
                } else {
                    let data = Object.assign({}, this.data);
                    this.data.CODICI = this.data.CODICI.filter((v, i) => this.data.CODICI.findIndex(r => r.text == v.text) == i);
                    data.CODICI = this.data.CODICI.filter(c => c.status).map(c => c.text);
                    if (data.CODICI.length > 0) {
                        res = await this.handleCallback(
                            () => this.axios.post(this.$store.getters.uri + '/referta', { referto: data }), false
                        );
                        const modalBody = [
                            { value: `<b>Numero codici refertati: ${data.CODICI.length}</b>` },
                            ...this.data.CODICI.filter(c => c.status).map(v => {return { value: `${v.text}: ${v.statusText}` }}),
                        ];
                        this.notifyWrapper(this.__('Procedura compiuta con successo'), true, modalBody);
                    } else
                        this.notifyWrapper(this.__('Spedizioni non trovate'), false);
                    this.clear();
                }
                return res;
            });
        },
        clear() {
            this.updateDateTime();
            this.data.CODICI[0] = { text: '' };
            this.data.CODICI.splice(1);
            this.$set(this, 'data', this.data);
            this.indexScanner = 0;
        },
        async searchBase(url, data = null) {
            let res = null;
            await this.storeLogTime(async () => {
                res = this.handleCallback(() => this.axios.post(url, data), false, true, false);
            });
            return res;
        },
    }
}
</script>
